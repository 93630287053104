import React, { useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import WavingFlag from 'waving-flag';
import mediaQueries from '../utils/mediaQueries';
import { fluidProperty } from '../utils/themeUtils';
import scroll from '../assets/scroll.svg';

const ANIMATED_IMG_WIDTH_MOBILE = 60;
const ANIMATED_IMG_WIDTH_DESKTOP = 80;

const Wrapper = styled.div`
  position: relative;
  @media screen and (min-width: 2497px) {
    padding-top: 4em;
  }
`;

const StyledContainer = styled.div<{ variant: string }>`
  position: absolute;
  z-index: -10;
  width: 100%;
  ${fluidProperty('right', -100, -250)}
  ${props =>
    props.variant === 'variant1' ? fluidProperty('top', 10, 60) : fluidProperty('top', 0, -100)}
  ${mediaQueries.mobile`
  ${props => (props.variant === 'variant1' ? 'top: -20px;' : 'top: 0;')}
    width: 200%;
    right: -25%;
    left: -40%;
  `}
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const RotatingImage = styled.img<{ variant?: string; widthDesktop: number; widthMobile: number }>`
  position: absolute;
  animation: ${rotate} 16s linear infinite;
  ${props =>
    props.variant === 'variant1'
      ? css`
          top: 45%;
          right: calc(7em + 250px);
        `
      : css`
          left: calc(12% - ${props.widthDesktop / 2}px);
          top: calc(50% - ${props.widthDesktop / 2}px);
          ${mediaQueries.desktop`
            left: calc(13% - ${props.widthDesktop / 2}px);
          `}
        `}
  ${props => fluidProperty('width', props.widthMobile, props.widthDesktop)}
  ${mediaQueries.mobile`
    left: calc(1.5rem + 20%);
    top: 30px;
  `}
`;

interface WavingFlagAnimationProps {
  variant: string;
}

const WavingFlagAnimation: React.FC<WavingFlagAnimationProps> = ({ variant }) => {
  const ref = useRef();
  useEffect(() => {
    let instance;
    let frameHandle;
    if (ref.current) {
      instance = new WavingFlag(
        ref.current,
        // these props can also be passed by the hook or derived from the viewport size
        // in order to have different configurations for desktop and mobile
        // be sure to handle them properly in `useEffect`
        { dpr: 2 },
        {
          lineSpacing: 200.0,
          lineWidth: 55.0,

          meshRows: 128,
          meshColumns: 256,
        }
      );
      instance.toggleInteraction();

      const onAnimationFrame = () => {
        instance.draw();

        frameHandle = requestAnimationFrame(onAnimationFrame);
      };

      frameHandle = requestAnimationFrame(onAnimationFrame);

      return () => {
        // cleanup function
        cancelAnimationFrame(frameHandle);
        instance?.destroy(); // this has to be done by @scb so that event listeners are removed
      };
    }
  }, [ref.current]);

  return (
    <Wrapper>
      <StyledContainer variant={variant} ref={ref}>
        <RotatingImage
          src={scroll}
          variant={variant}
          widthDesktop={ANIMATED_IMG_WIDTH_DESKTOP}
          widthMobile={ANIMATED_IMG_WIDTH_MOBILE}
        />
      </StyledContainer>
    </Wrapper>
  );
};

export default WavingFlagAnimation;
