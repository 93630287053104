import React from 'react';
import styled, { css } from 'styled-components';
import Button from './Button';
import { selectColor, selectSize } from '../utils/themeUtils';
import mediaQueries from '../utils/mediaQueries';

const StyledCallToActionBtn = styled.div<{ decorateLine: string }>`
  display: flex;
  align-items: center;
  position: relative;
  ${props =>
    props.decorateLine === 'vertical'
      ? css`
          flex-direction: column-reverse;
          margin-top: 30px;
          ${mediaQueries.mobile`
            margin-top: 100px;
          `}
        `
      : css`
          height: 3em;

          a {
            margin-left: 33%; //to skip one of 3 columns in header
          }

          ${mediaQueries.mobile`
            justify-content: center;
            height: none;
            
            a {
              margin-left: 0;
              position: relative;
              right: auto;
            }
          `}
        `}
`;

const StyledLine = styled.div<{ decorateLine: string }>`
  ${props =>
    props.decorateLine === 'vertical'
      ? css`
          height: 120px;
          width: 0;
          border: 1px solid ${selectColor('primary')};
        `
      : css`
          height: 0;
          border-bottom: ${selectSize('underlineWidth')} solid ${selectColor('primary')};
          flex: 1 0;
          margin-right: -90px; //to ignore container margin
        `}

  ${mediaQueries.mobile`
    display: none;
  `}
`;

//workaround for missing grid system
const VerticalButtonWrapper = styled.div`
  justify-content: center;
`;

const LeftVerticalSpacer = styled.div`
  flex: 0 0 33%;
  ${mediaQueries.mobile`
    display: none;
  `}
`;

const RightVerticalSpacer = styled.div`
  flex: 1 1 auto;
  ${mediaQueries.mobile`
    display: none;
  `}
`;

export interface CallToActionBtnProps {
  style?: Record<string, string>;
  className?: string;
  buttonText: string;
  decorateLine: 'horizontal' | 'vertical';
  link: {
    field?: string;
    external?: string;
    internal?: string;
  };
}

const CallToActionButton: React.FC<CallToActionBtnProps> = ({
  style,
  className,
  buttonText,
  link,
  decorateLine,
}) => (
  <React.Fragment>
    {decorateLine === 'vertical' ? (
      <VerticalButtonWrapper>
        <LeftVerticalSpacer />
        <StyledCallToActionBtn decorateLine={decorateLine}>
          <Button style={style} className={className} buttonText={buttonText} link={link} />
          <StyledLine decorateLine={decorateLine} />
        </StyledCallToActionBtn>
        <RightVerticalSpacer />
      </VerticalButtonWrapper>
    ) : (
      <StyledCallToActionBtn decorateLine={decorateLine}>
        <Button style={style} className={className} buttonText={buttonText} link={link} />
        <StyledLine decorateLine={decorateLine} />
      </StyledCallToActionBtn>
    )}
  </React.Fragment>
);

export default CallToActionButton;
