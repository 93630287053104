import React from 'react';
import styled from 'styled-components';
import { changeLanguage, getCurrentLanguage, getLanguages } from '../helpers/AppHelpers';
import { selectColor, selectFont, selectSize } from '../utils/themeUtils';

const LanguageItem = styled.span<{ active: boolean }>`
  color: ${props => (props.active ? selectColor('light') : selectColor('grey'))};
  text-transform: uppercase;
  cursor: pointer;
  margin-right: ${selectSize('paddingMedium')}em;
  ${selectFont('titleTiny')}
`;

const LanguageSwitcher: React.FC = () => {
  const currentLanguage = getCurrentLanguage();
  return (
    <div>
      {getLanguages().map(lang => (
        <LanguageItem
          key={`lang-${lang}`}
          active={currentLanguage === lang}
          onClick={() => changeLanguage(lang)}
        >
          {lang}
        </LanguageItem>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
