export default {
  primary: '#206384',
  dark: '#1B1C1E',
  shareButtonBG: '#121315',
  borderDark: '#121315',
  grey: '#A3A3A3',
  lightGrey: '#F0F0F0',
  light: '#FFFFFF',
  opaPrimary: 'rgba(32, 99, 132, 0.5)',
  videoButtonBackground: 'rgba(0, 0, 0, 0.3)',
  lightBackground: '#F0F0F0',
};
