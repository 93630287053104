import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { fluidProperty, selectColor, selectFont } from '../utils/themeUtils';
import { EditableArea } from '@magnolia/react-editor';
import arrowRightShort from '../assets/arrow_right_short.svg';
import arrowRightLong from '../assets/arrow_right_long.svg';

const StyledContainer = styled.div<{ arrowRight: string }>`
  display: flex;
  ${props =>
    props.arrowRight === 'true'
      ? css`
          justify-content: space-between;
        `
      : ''};
`;

const StyledLink = styled.a<{ arrowRight: string }>`
  text-decoration: none;
  display: flex;
  margin-bottom: 1em;
  flex-direction: column;
  cursor: pointer;

  ${props =>
    props.arrowRight === 'true'
      ? css`
          align-items: center;
          flex-direction: row;
          margin-bottom: 0;
        `
      : ''};
`;

const StyledSectionTitle = styled.div`
  color: ${selectColor('grey')};
  ${selectFont('readMoreText')}
`;

const arrowAnimation = keyframes`
  0% {
    transform: scaleX(0);
  },
  100% {
    transform: scaleX(1);
  }
`;

const StyledShortArrow = styled.img`
  margin-left: 20px;
`;

const StyledLongArrow = styled.img`
  ${fluidProperty('width', 170, 225)}
  ${fluidProperty('margin-top', 0, 10)}
  transition: transform 0.25s ease-out;
  transform-origin: left;

  ${StyledLink}:hover & {
    animation: ${arrowAnimation} 0.5s;
  }
`;

export interface Switchable {
  field?: string;
  external?: string;
  internal?: string;
}

export interface ReadMoreLinkProps {
  link: Switchable;
  title: unknown;
  arrowText: string;
  metadata: unknown;
  arrowRight: string;
  anchorLinkId?: string;
}

export interface LinkPathProps {
  switchable: Switchable;
  anchorLinkId?: string;
  arrowRight: string;
}

const LinkPath: React.FC<LinkPathProps> = ({ switchable, children, anchorLinkId, arrowRight }) => {
  const { field, internal, external } = switchable || {};

  if (!field || field === 'none') {
    return <StyledLink arrowRight={arrowRight}>{children}</StyledLink>;
  }

  if (field === 'internal' && internal) {
    let linkPath = '.' + internal['@path'];
    linkPath += anchorLinkId ? '#' + anchorLinkId : '';

    return (
      <StyledLink arrowRight={arrowRight} href={linkPath}>
        {children}
      </StyledLink>
    );
  } else if (field === 'external' && external) {
    return (
      <StyledLink
        arrowRight={arrowRight}
        href={switchable.external}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </StyledLink>
    );
  }

  return <StyledLink arrowRight={arrowRight}>{children}</StyledLink>;
};

const ReadMoreLink: React.FC<ReadMoreLinkProps> = ({
  /* className, */
  link,
  title,
  metadata,
  arrowText,
  arrowRight,
  anchorLinkId,
}) => {
  return (
    <StyledContainer arrowRight={arrowRight}>
      {title && <EditableArea content={title} parentTemplateId={metadata['mgnl:template']} />}
      <LinkPath arrowRight={arrowRight} switchable={link} anchorLinkId={anchorLinkId}>
        <StyledSectionTitle>{arrowText}</StyledSectionTitle>
        {arrowRight === 'true' ? (
          <StyledShortArrow src={arrowRightShort} alt="arrow right short" />
        ) : (
          <StyledLongArrow src={arrowRightLong} alt="arrow right long" />
        )}
      </LinkPath>
    </StyledContainer>
  );
};

export default ReadMoreLink;
