import React from 'react';
import styled from 'styled-components';
import { EditableArea } from '@magnolia/react-editor';
import mediaQueries from '../utils/mediaQueries';
import { selectSize } from '../utils/themeUtils';

const Base = styled.ul`
  margin-bottom: ${selectSize('spacerMedium')}em;
  ${mediaQueries.mobile`
    width: 50%;
    margin-bottom: 0;
  `}
`;

export interface FooterListProps {
  items: unknown;
  metadata: unknown;
}

const FooterList: React.FC<FooterListProps> = ({ items, metadata }) => (
  <Base>
    {items && <EditableArea content={items} parentTemplateId={metadata['mgnl:template']} />}
  </Base>
);

export default FooterList;
