import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  Color,
  Font,
  Size,
  ThemeGroup,
  ThemeKeyOrFunc,
  ThemeKeySelector,
  TransformFunc,
  ZIndex,
} from '../config/theme';
import mediaQueries, { SIZES } from './mediaQueries';

export interface PropsWithTheme {
  theme: unknown;
}

/* eslint-disable indent, no-unused-vars */
export const select = <T extends string>(
  group: ThemeGroup,
  themeKeyOrFunc: ThemeKeyOrFunc<T>,
  transform: TransformFunc = null
) => (props: PropsWithTheme): T => {
  let transformFunc = null;
  const { theme } = props;

  if (transform) {
    if (isNumber(transform)) {
      transformFunc = (v: number) => (transform as number) * v;
    } else if (isFunction(transform)) {
      transformFunc = transform;
    } else {
      // eslint-disable-next-line no-console
      console.warn('transform has to be function or number');
    }
  }

  const themeKey = isFunction(themeKeyOrFunc)
    ? (themeKeyOrFunc as ThemeKeySelector<T>)(props)
    : themeKeyOrFunc;

  const value = get(theme[group], themeKey, themeKey);
  if (transformFunc) {
    return transformFunc(value);
  }
  return value;
};

export const selectColor = (
  themeKeyOrFunc: ThemeKeyOrFunc<Color>,
  transform?: TransformFunc
): ((props: PropsWithTheme) => string) => select('colors', themeKeyOrFunc, transform);

export const selectSize = (
  themeKeyOrFunc: ThemeKeyOrFunc<Size>,
  transform?: TransformFunc
): ((props: PropsWithTheme) => string) => select('sizes', themeKeyOrFunc, transform);

export const selectZIndex = (
  themeKeyOrFunc: ThemeKeyOrFunc<ZIndex>,
  transform?: TransformFunc
): ((props: PropsWithTheme) => string) => select('zIndexes', themeKeyOrFunc, transform);

export const selectGridWidth = (
  columns: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
): unknown =>
  select(
    'sizes',
    'desktopGrid',
    (grid: { col: number; gutter: number }) => grid.col * columns + grid.gutter * (columns - 1)
  );
/*
export const selectBorder = (themeKeyOrFunc: ThemeKeyOrFunc<Borders>, transform?: TransformFunc) =>
  select("borders", themeKeyOrFunc, transform);
  */
export const selectFont = (
  themeKeyOrFunc: ThemeKeyOrFunc<Font>,
  transform?: TransformFunc
): ((props: PropsWithTheme) => string) => select('fonts', themeKeyOrFunc, transform);

export const pixelToRem = (pixel: number): number => {
  return pixel / 16;
};

export const relativeProperty = (baseValue: number, baseVw = SIZES.desktop): string => {
  return `(100vw / ${baseVw} * ${baseValue})`;
};
export const relativePropertyBigScreen = (baseValue: number, baseVw = SIZES.desktop): string => {
  return `(2496px / ${baseVw} * ${baseValue})`;
};

export const pixelToRemIfRequired = (value: number, unit: 'px' | 'rem' | 'em'): number => {
  return unit !== 'px' ? pixelToRem(value) : value;
};

export const fluidProperty = (
  propertyName: string,
  mobileValue: number,
  desktopValue: number,
  unit: 'px' | 'rem' | 'em' = 'px',
  mobileVw = SIZES.mobile,
  desktopVw = SIZES.desktop,
  bigScreenVw = SIZES.bigScreen
): FlattenSimpleInterpolation => {
  // bigScreenValue = desktopValue relatively scaled to screen width by 50%
  const bigScreenValue = desktopValue * (bigScreenVw / (desktopVw + (bigScreenVw - desktopVw) / 2));
  return css`
    ${propertyName}: ${bigScreenValue}${unit};
    ${mediaQueries.bigScreen`
      ${propertyName}: calc(${desktopValue}${unit} + ${bigScreenValue - desktopValue} * ((100vw -
      ${pixelToRemIfRequired(desktopVw, unit)}${unit}) /
      ${pixelToRemIfRequired(bigScreenVw, unit) - pixelToRemIfRequired(desktopVw, unit)}));
    `}
    ${mediaQueries.desktop`
      ${propertyName}: calc(${mobileValue}${unit} + ${desktopValue - mobileValue} * ((100vw -
      ${pixelToRemIfRequired(mobileVw, unit)}${unit}) /
      ${pixelToRemIfRequired(desktopVw, unit) - pixelToRemIfRequired(mobileVw, unit)}));
    `}
    ${mediaQueries.mobile`
      ${propertyName}: ${mobileValue}${unit};
    `}
  `;
};

// experimental
/*
export const iff = condition => (first: any, ...vars: any[]) => {
  const ifResult = css(first, ...vars);
  const result = (props: any) => (condition(props) ? ifResult : undefined);
  result.else = (f: any, ...othervars: any[]) => (props: any) =>
    condition(props) ? ifResult : css(f, ...othervars);
  return result;
};
*/
export const hideScrollbars = css`
  -webkit-overflow-scrolling: touch; /* force gravity scroll on touch devices*/
  -ms-overflow-style: none; /* hide scrollbar on ie11 */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;
