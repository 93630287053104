import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Reset } from 'styled-reset';
import { selectColor, selectFont } from '../utils/themeUtils';

const Styles = createGlobalStyle`
  html {
    ${selectFont('base')}
    overflow-x: hidden;
  }
  body {
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    background-color: ${selectColor('dark')};
  }
  * {
      box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  p > strong {
    color: ${selectColor('primary')};
  }
`;

// eslint-disable-next-line react/display-name
export default React.memo(() => (
  <>
    <Reset />
    <Styles />
  </>
));
