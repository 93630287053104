import React, { useState } from 'react';
import styled from 'styled-components';
import { getAPIBase } from '../helpers/AppHelpers';
import mediaQueries from '../utils/mediaQueries';
import { selectColor, selectFont, selectSize } from '../utils/themeUtils';

/* eslint-disable no-unused-vars */
const Base = styled.div`
  ${mediaQueries.mobile`
    margin-bottom: ${selectSize('spacerLarge')}em;
  `}
`;

const TeamMemberNameStyled = styled.h3`
  ${selectFont('teamMember')};
  color: ${selectColor('grey')};
  display: inline;

  &:hover {
    color: ${selectColor('light')};
  }
`;

const TeamMemberImage = styled.img<{ isActive: boolean }>`
  display: ${props => (props.isActive ? 'block' : 'none')};
  position: fixed;
  height: 12em;
  top: -18em;
  left: 0;
  transform: translate(0, 0);
  pointer-events: none;
`;

export interface TeamMemberNameProps {
  member: { name: string };
  image?: { link: string };
  handleMouseOver: (e?: React.MouseEvent<HTMLHeadingElement, MouseEvent>, id?: string) => void;
  handleMouseLeave: () => void;
}

const TeamMemberName: React.FC<TeamMemberNameProps> = ({
  member,
  image,
  handleMouseOver,
  handleMouseLeave,
}) => {
  return (
    <TeamMemberNameStyled
      onMouseMove={e => {
        image?.link ? handleMouseOver(e, member['@id']) : null;
      }}
      onMouseLeave={handleMouseLeave}
    >
      {member.name}
    </TeamMemberNameStyled>
  );
};

export interface Switchable {
  field?: string;
  external?: string;
  internal?: string;
}

export interface TeamMemberNameLinkProps {
  switchable: Switchable;
}

const TeamMemberNameLink: React.FC<TeamMemberNameLinkProps> = ({ switchable, children }) => {
  if (!switchable.field || switchable.field === 'none') {
    return <a>{children}</a>;
  }

  return switchable.field === 'external' ? (
    <a href={switchable.external} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <a href={switchable.internal['@path']}>{children}</a>
  );
};

const fetchTeamMember = async teamMeberId => {
  const response = await fetch(
    getAPIBase() + process.env.REACT_APP_MGNL_API_TEAM_MEMBERS + '?@jcr:uuid=' + teamMeberId
  );
  const data = await response.json();
  return data.results[0];
};

export interface TeamProps {
  team: {
    name: string;
    members: [{ name: string; switchable: Switchable; image: { '@link': string } }];
  };
  isMobileLayout: boolean;
}

const Team: React.FC<TeamProps> = ({ team, isMobileLayout }) => {
  const [teamMemberImages, setTeamMemberImages] = useState([]);
  const [mouseCoordinates, setMouseCoordinates] = useState({ x: null, y: null });
  const [hoveredMemberId, setHoveredMemberId] = useState(null);

  const handleMouseOver = (e, memberId) => {
    setMouseCoordinates({ x: e.screenX, y: e.screenY });
    setHoveredMemberId(memberId);
  };

  const handleMouseLeave = () => {
    setMouseCoordinates({ x: null, y: null });
    setHoveredMemberId(null);
  };

  React.useEffect(() => {
    const fetchTeamDetails = async () => {
      team?.members?.map(member => {
        fetchTeamMember(member['@id']).then(res => {
          setTeamMemberImages(currentValue => [
            ...currentValue,
            { memberId: member['@id'], link: res.image ? res.image['@link'] : null },
          ]);
        });
      });
    };
    if (!isMobileLayout) {
      fetchTeamDetails();
    }
    return () => setTeamMemberImages([]);
  }, [team]);

  return (
    <Base>
      {team?.members
        ? team.members.map(member => {
            const image = teamMemberImages.find(item => item.memberId === member['@id']);
            return (
              <div key={member['@id']}>
                {member.switchable?.field ? (
                  <TeamMemberNameLink switchable={member.switchable}>
                    <TeamMemberName
                      member={member}
                      image={image}
                      handleMouseOver={handleMouseOver}
                      handleMouseLeave={handleMouseLeave}
                    />
                  </TeamMemberNameLink>
                ) : (
                  <TeamMemberName
                    member={member}
                    image={image}
                    handleMouseOver={handleMouseOver}
                    handleMouseLeave={handleMouseLeave}
                  />
                )}
                {image?.link ? (
                  <TeamMemberImage
                    src={image.link}
                    isActive={hoveredMemberId === member['@id']}
                    style={{
                      transform: `translate(${mouseCoordinates.x || 0}px, ${
                        mouseCoordinates.y || 0
                      }px)`,
                    }}
                  />
                ) : null}
              </div>
            );
          })
        : null}
    </Base>
  );
};

export default Team;
