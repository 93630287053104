import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import { selectFont } from '../utils/themeUtils';
import styled from 'styled-components';

const StyledTitle = styled.h3`
  ${selectFont('readMoreText')}
`;

export interface CategoryItemProps {
  title: string;
  item: unknown;
  metadata: unknown;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ item, title, metadata }) => (
  <>
    <StyledTitle>{title}</StyledTitle>
    {item && <EditableArea content={item} parentTemplateId={metadata['mgnl:template']} />}
  </>
);

export default CategoryItem;
