import { EditableArea } from '@magnolia/react-editor';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Column from '../components/Column';
import mediaQueries from '../utils/mediaQueries';
import { screenReaderOnly } from '../utils/screenReaderOnly';
import { fluidProperty, selectColor, selectFont, selectSize } from '../utils/themeUtils';
import arrowRightLong from '../assets/arrow_right_long.svg';
import ServiceLinkItem from '../components/ServiceLinkItem';
import { Link } from 'react-router-dom';

const ScreenReaderOnlyHeading1 = styled.h1`
  ${screenReaderOnly}
`;

const StyledContainer = styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  ${fluidProperty('margin-bottom', 60, 200)}
  ${fluidProperty('margin-top', 147, 227)}

  &::after {
    content: '';
    height: 0;
    clear: both;
    overflow: hidden;
    display: block;
    visibility: hidden;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledProjectSuperTitle = styled.div`
  ${selectFont('titleMedium')}
  color: ${selectColor('grey')};
`;

const StyledProjectTitle = styled.div`
  ${selectFont('titleLarge')}
  text-align: center;
  margin: auto ${selectSize('paddingHuge')}rem ${selectSize('paddingHuge')}rem
    ${selectSize('paddingHuge')}rem;
`;

const StyledImg = styled.img`
  width: 100%;
  max-height: 800px;
  object-fit: cover;
  object-position: top;
`;

const StyledColumnsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${selectColor('lightGrey')};
  color: ${selectColor('dark')};
  padding: ${selectSize('paddingExtraLarge')}rem ${selectSize('paddingLarge')}rem;

  ${mediaQueries.mobile`
    flex-direction: column-reverse;
  `}
`;

const StyledColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  flex: 2 1 0%;
`;

const StyledLeftColumn = styled(StyledColumn)`
  flex: 1 1 0%;
  ${mediaQueries.mobile`
    flex-direction: row;
  `}
`;

const StyledFont = styled.div`
  ${selectFont('readMoreText')}

  ${mediaQueries.mobile`
    flex: 1;
  `}
`;

const StyledTeams = styled(StyledFont)`
  flex: 1;
  top: 2.3em;
  position: relative;
  margin-top: 0 !important;
  ${mediaQueries.mobile`
     top: 0;
    position: relative;
  `}
`;

const StyledTextGrey = styled.div`
  color: ${selectColor('grey')};
`;

const StyledArrowContainer = styled.div`
  cursor: pointer;
  ${mediaQueries.mobile`
    flex: 1;
  `}
`;

const StyledSectionTitle = styled.div`
  color: ${selectColor('grey')};
  ${selectFont('readMoreText')};
`;
const StyledLongArrow = styled.img`
  ${fluidProperty('width', 170, 225)}
  ${fluidProperty('margin-top', 0, 10)}
`;

const StyledProjectDescriptionContainer = styled.div`
  flex: 1;
  margin-bottom: ${selectSize('MarginHuge')}rem;
`;

const StyledAwwward = styled.span`
  color: ${selectColor('grey')};
  ${selectFont('textExtraTiny')}
  ${fluidProperty('margin-right', 30, 50)}
`;

const StyledProjectDescription = styled.p`
  ${selectFont('projectDescription')}
  width:90%;
  ${mediaQueries.mobile`
    width:100%;
  `}
`;

const StyledIndustryAndServicesContainer = styled.div`
  display: flex;
  ${mediaQueries.mobile`
    margin-bottom: ${selectSize('paddingExtraLarge')}rem;
  `}
`;
const OuterLink = styled(Link)`
  cursor: pointer;
  z-index: 9999;
`;

const OuterLinkExternal = styled.a`
  cursor: pointer;
  z-index: 9999;
`;

const StyledIndustry = styled(StyledFont)`
  ${fluidProperty('margin-right', 0, 70)}
`;

interface ProjectPageProps {
  main?: unknown;
  metadata?: unknown;
  windowTitle: string;
  title?: string;
  arrowImgTitle?: string;
  arrowLinkChoose?: {
    field?: string;
    external?: string;
    internal?: string;
  };
  description?: string;
  keywords?: string;
  footer?: unknown;
  projectTitle: string;
  projectSuperTitle: string;
  image: { '@link': string };
  twitterImage: { '@link': string };
  openGraphImage: { '@link': string };
  ogUrl?: string;
  twitterUrl?: string;
  ogType?: string;
  ogTitle?: string;
  ogDescription?: string;
  twitterCard?: string;
  twitterTitle?: string;
  twitterDescription?: string;

  projectDescription: string;
  teamLabel: string;
  teams: [{ string }];
  awwwards: [string];
  industryComposite: {
    industryTitle: string;
    industryCategory: { displayName: string };
    industryLink: { field: string; external: string; internal: { '@path': string } };
  };
  servicesComposite: { servicesTitle: string; servicesList: unknown };
}

const Project: React.FC<ProjectPageProps> = ({
  main,
  windowTitle,
  title,
  description,
  keywords,
  footer,
  projectTitle,
  projectSuperTitle,
  image,
  twitterImage,
  openGraphImage,
  ogUrl,
  twitterUrl,
  ogType,
  ogTitle,
  ogDescription,
  twitterTitle,
  twitterDescription,
  twitterCard,
  projectDescription,
  teamLabel,
  teams,
  awwwards,
  industryComposite,
  servicesComposite,
  arrowImgTitle,
  arrowLinkChoose,
}) => {
  let industryLink;
  const industryLinkType = industryComposite?.industryLink['field'];

  if (industryLinkType === 'external') {
    industryLink = industryComposite?.industryLink?.external;
  } else if (industryLinkType === 'internal') {
    industryLink =
      industryComposite?.industryLink?.internal &&
      industryComposite?.industryLink?.internal['@path'];
  }
  let arrowLinkPath;
  if (arrowLinkChoose == undefined) {
    arrowLinkPath = '#';
  } else {
    if (arrowLinkChoose.field && arrowLinkChoose.field === 'external') {
      arrowLinkPath = arrowLinkChoose?.external;
    } else if (arrowLinkChoose.field && arrowLinkChoose.field === 'internal') {
      arrowLinkPath = arrowLinkChoose?.internal && arrowLinkChoose.internal['@path'];
    } else {
      arrowLinkPath = '#';
    }
  }

  let openGraphImgUrl, twitterImgUrl;

  if (openGraphImage != undefined || openGraphImage != null) {
    if (openGraphImage['@link'] != undefined) {
      openGraphImgUrl = 'https://www.panter.ch' + openGraphImage['@link'];
    }
  } else {
    openGraphImgUrl = '';
  }

  if (twitterImage != undefined || twitterImage != null) {
    if (twitterImage['@link'] != undefined) {
      twitterImgUrl = 'https://www.panter.ch' + twitterImage['@link'];
    }
  } else {
    twitterImgUrl = '';
  }

  return (
    <div>
      <Helmet>
        <title>{windowTitle}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <ScreenReaderOnlyHeading1 role="status" aria-live="polite" aria-atomic="true">
        {title}
      </ScreenReaderOnlyHeading1>
      <StyledContainer>
        <StyledTitle>
          <StyledProjectSuperTitle>{projectSuperTitle}</StyledProjectSuperTitle>
          <StyledProjectTitle>{projectTitle}</StyledProjectTitle>
          <StyledImg src={image['@link']} alt={projectSuperTitle + ' - ' + projectTitle} />
        </StyledTitle>
        <StyledColumnsLayout>
          <StyledLeftColumn size={1}>
            <StyledTeams>
              <div>{teamLabel}</div>
              <StyledTextGrey>
                {teams?.map(team => (
                  <span key={team['@id']}>{team['name']}</span>
                ))}
              </StyledTextGrey>
            </StyledTeams>
            {arrowLinkChoose != undefined &&
              (arrowLinkChoose.field === 'external' ? (
                <OuterLinkExternal href={arrowLinkPath} target="_blank" rel="noreferrer">
                  <StyledArrowContainer>
                    <StyledSectionTitle>{arrowImgTitle}</StyledSectionTitle>
                    <StyledLongArrow src={arrowRightLong} alt="arrow right long" />
                  </StyledArrowContainer>
                </OuterLinkExternal>
              ) : (
                <OuterLink to={arrowLinkPath}>
                  <StyledArrowContainer>
                    <StyledSectionTitle>{arrowImgTitle}</StyledSectionTitle>
                    <StyledLongArrow src={arrowRightLong} alt="arrow right long" />
                  </StyledArrowContainer>
                </OuterLink>
              ))}
          </StyledLeftColumn>
          <StyledColumn size={2}>
            <StyledProjectDescriptionContainer>
              <div>
                {awwwards?.map((awwward, index) => (
                  <StyledAwwward key={index}>{awwward}</StyledAwwward>
                ))}
                {!awwwards ? '' : <StyledAwwward> </StyledAwwward>}
              </div>
              <StyledProjectDescription>{projectDescription}</StyledProjectDescription>
            </StyledProjectDescriptionContainer>
            <StyledIndustryAndServicesContainer>
              <StyledIndustry>
                <div>{industryComposite?.industryTitle}</div>

                {industryLinkType && industryLink ? (
                  <StyledTextGrey>
                    <a
                      href={industryLink}
                      target={industryLinkType === 'external' ? '_blank' : ''}
                      rel="noreferrer"
                    >
                      <span>{industryComposite?.industryCategory?.displayName}</span>
                    </a>
                  </StyledTextGrey>
                ) : (
                  <StyledTextGrey>
                    <span>{industryComposite?.industryCategory?.displayName}</span>
                  </StyledTextGrey>
                )}
              </StyledIndustry>
              <StyledFont>
                <div>{servicesComposite?.servicesTitle}</div>
                <StyledTextGrey>
                  {servicesComposite?.servicesList['@nodes']?.map(
                    (serviceKey: string, index: number) => {
                      return (
                        <Fragment key={index}>
                          <ServiceLinkItem
                            text={
                              servicesComposite.servicesList[serviceKey].serviceCategory[
                                'displayName'
                              ]
                            }
                            link={servicesComposite.servicesList[serviceKey].serviceLink}
                            wrapped={false}
                          />

                          {index !== servicesComposite.servicesList['@nodes'].length - 1
                            ? ', '
                            : ''}
                        </Fragment>
                      );
                    }
                  )}
                </StyledTextGrey>
              </StyledFont>
            </StyledIndustryAndServicesContainer>
          </StyledColumn>
        </StyledColumnsLayout>
      </StyledContainer>
      {main && <EditableArea content={main} />}

      <footer className="Footer">
        {footer && <EditableArea className="Area" content={footer} />}
      </footer>
    </div>
  );
};

export default Project;
