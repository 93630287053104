import React from 'react';

export const getAllItems = (items, nodesListSplited, objectNested, arrayOfNodes) => {
  if (items) {
    for (let i = 0; i < nodesListSplited.length; i++) {
      for (const key of Object.keys(items)) {
        if (items[key] instanceof Object && nodesListSplited[i] === key) {
          objectNested[key] = items[key];
        } else if (key == '@nodes' || items[key] instanceof Array) {
          objectNested[key] = [nodesListSplited[i]];
        } else if (items[key] instanceof Object) {
        } else {
          objectNested[key] = items[key];
        }
      }
      arrayOfNodes.push(objectNested);
      objectNested = {};
    }
  }
};

export const getFirstItem = (items, nodesListSplited, firstObjectNested, firstNode) => {
  if (items) {
    for (let i = 0; i < 1; i++) {
      for (const key of Object.keys(items)) {
        if (items[key] instanceof Object && nodesListSplited[i] === key) {
          firstObjectNested[key] = items[key];
        } else if (key == '@nodes' || items[key] instanceof Array) {
          firstObjectNested[key] = [nodesListSplited[i]];
        } else if (items[key] instanceof Object) {
        } else {
          firstObjectNested[key] = items[key];
        }
      }
      firstNode.push(firstObjectNested);
      firstObjectNested = {};
    }
  }
};

export const getAllItemsWithoutFirst = (
  items,
  nodesListSplited,
  eliminateFirstObjectNested,
  eliminateFirstNode
) => {
  if (items) {
    for (let i = 1; i < nodesListSplited.length; i++) {
      for (const key of Object.keys(items)) {
        if (items[key] instanceof Object && nodesListSplited[i] === key) {
          eliminateFirstObjectNested[key] = items[key];
        } else if (key == '@nodes' || items[key] instanceof Array) {
          eliminateFirstObjectNested[key] = [nodesListSplited[i]];
        } else if (items[key] instanceof Object) {
        } else {
          eliminateFirstObjectNested[key] = items[key];
        }
      }
      eliminateFirstNode.push(eliminateFirstObjectNested);
      eliminateFirstObjectNested = {};
    }
  }
};
