import { EditableArea } from '@magnolia/react-editor';
import React from 'react';
import styled from 'styled-components';
import mediaQueries from '../utils/mediaQueries';
import { selectSize } from '../utils/themeUtils';

const Base = styled.div`
  margin-bottom: ${selectSize('spacerMedium')}em;
  ${mediaQueries.mobile`
    margin-bottom: ${selectSize('spacerLarge')}em;;
  `}
`;

export interface SocialLinksProps {
  links: unknown;
  metadata: unknown;
}

const SocialLinks: React.FC<SocialLinksProps> = ({ links, metadata }) => (
  <Base>
    {links && <EditableArea content={links} parentTemplateId={metadata['mgnl:template']} />}
  </Base>
);

export default SocialLinks;
