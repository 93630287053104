import styled from 'styled-components';
import { selectSize } from '../utils/themeUtils';
import mediaQueries from '../utils/mediaQueries';

const Container = styled('div')`
  padding: 0 ${selectSize('paddingLarge')}rem;
  max-width: ${selectSize('bigScreenWidth')}px;
  ${mediaQueries.mobile`
      margin: 100px auto;
  `}
  ${mediaQueries.bigScreen`
      margin: 100px auto;
  `}
    ${mediaQueries.desktop`
      margin: 90px auto;
  `}
`;

export default Container;
