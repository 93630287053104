import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import styled from 'styled-components';
import mediaQueries from '../utils/mediaQueries';

export interface JumpLinkListProps {
  items: unknown;
  metadata: unknown;
}

const StyledList = styled.ul`
  margin-bottom: 100px;
  ${mediaQueries.mobile`
    position: relative;
    margin-bottom: 100px;
  `}
`;

const JumpLinkList: React.FC<JumpLinkListProps> = ({ items, metadata }) => (
  <>
    <StyledList>
      {items && <EditableArea content={items} parentTemplateId={metadata['mgnl:template']} />}
    </StyledList>
  </>
);

export default JumpLinkList;
