import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { selectColor } from '../utils/themeUtils';
import mediaQueries from '../utils/mediaQueries';
import { useDispatch, useSelector } from 'react-redux';
import { NotesState } from '../notesReducer';
import { addCategoryList, removeCategoryList } from '../actions';

const StyledCategoryButton = styled.button<{ shouldMakeClicked: boolean }>`
  align-items: center;
  cursor: pointer;
  padding: 0px 20px;
  height: 40px;
  background-color: ${selectColor('shareButtonBG')};
  border: none;
  color: white;
  margin-right: 2em;
  ${mediaQueries.mobile`
    margin-right: 0.5em;
    margin-top: 1em;
  `}
  ${props =>
    props.shouldMakeClicked === true
      ? css`
          background-color: ${selectColor('lightGrey')};
          color: black;
        `
      : css`
          background-color: ${selectColor('shareButtonBG')};
          color: ${selectColor('grey')}; ;
        `};

  &:focus {
    outline: none;
  }
  & img {
    display: none;
    ${mediaQueries.mobile`
      display: inline;
    `}
  }

  & span {
    color: ${selectColor('grey')};
    font-size: 15px;
    line-height: 32px;
    ${mediaQueries.mobile`
      display: none;
    `}
  }

  &:hover {
    & span {
      color: ${selectColor('light')};
    }
    color: black;
    background-color: white;
  }
`;

export interface CategoryItemProps {
  category: { displayName: string };
}

const CategoryButtonItem: React.FC<CategoryItemProps> = ({ category }) => {
  const [filterCategory, setFilterCategory] = useState(false);
  const categoryList = useSelector<NotesState, NotesState['categoryList']>(
    state => state.categoryList
  );
  const dispatch = useDispatch();

  const setActiveCategory = () => {
    if (categoryList.includes(category?.['@name'].toLowerCase())) {
      dispatch(removeCategoryList(category?.['@name'].toLowerCase()));
      setFilterCategory(false);
    } else {
      dispatch(addCategoryList(category?.['@name'].toLowerCase()));
      setFilterCategory(true);
    }
  };

  return (
    <>
      <StyledCategoryButton shouldMakeClicked={filterCategory} onClick={setActiveCategory}>
        {category?.['@name']}
      </StyledCategoryButton>
    </>
  );
};

export default CategoryButtonItem;
