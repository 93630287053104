import React from 'react';
import styled from 'styled-components';
import mediaQueries from '../utils/mediaQueries';
import { fluidProperty, selectColor, selectFont } from '../utils/themeUtils';

const QuoteContainer = styled.div`
  width: 85%;
  ${mediaQueries.mobile`
    width: 100%;
  `}
`;

const StyledPara = styled.p`
  ${selectFont('textLarge')}
`;

const TitleContainer = styled.div`
  display: flex;
  ${fluidProperty('margin-top', 60, 100)}
  ${selectFont('titleTiny')}
  position: relative;
  bottom: 90px;

  ${mediaQueries.mobile`
    flex-direction: column;
    padding-top:3em;
  `}
`;

const StyledName = styled.div`
  margin-right: 5px;
`;

const StyledTitle = styled.div`
  color: ${selectColor('grey')};
  margin-top: 0px !important;
  ${mediaQueries.mobile`
    margin-top:-8px !important;
  `}
`;

export interface QuoteProps {
  name: string;
  title: string;
  quoteText: string;
}

const Quote: React.FC<QuoteProps> = ({ name, title, quoteText }) => (
  <QuoteContainer>
    <StyledPara dangerouslySetInnerHTML={{ __html: quoteText }} />
    <TitleContainer>
      <StyledName>{name}</StyledName>
      <StyledTitle>{title}</StyledTitle>
    </TitleContainer>
  </QuoteContainer>
);

export default Quote;
