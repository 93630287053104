import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EditableArea } from '@magnolia/react-editor';
import mediaQueries from '../utils/mediaQueries';
import { selectColor, selectFont, selectSize } from '../utils/themeUtils';
import { useDispatch, useSelector } from 'react-redux';
import { NotesState } from '../notesReducer';
import { addRedPage, removeRedPage } from '../actions';
const Base = styled.ul`
  margin-bottom: ${selectSize('spacerMedium')}em;
  ${mediaQueries.mobile`
    width: 100%;
    margin-bottom: 0;
  `}
`;
const DesktopWrapper = styled.div`
  ${mediaQueries.mobile`
    display:none;
  `}
`;
const MobileWrapper = styled.div`
  ${mediaQueries.mobile`
    display:block;
  `}
  display:none;
`;
const StyledButton = styled.button`
  text-decoration: none;
  color: ${selectColor('light')};
  border: 2px solid ${selectColor('primary')};
  padding: 3px 75px;
  cursor: pointer;
  letter-spacing: 1px;
  display: flex;
  margin-left: 33%;
  text-align: center;
  background-color: ${selectColor('dark')};

  ${selectFont('buttonText')}
  &:hover,
  &:focus {
    outline: ${selectColor('opaPrimary')} solid 4px;
  }

  ${mediaQueries.mobile`
        padding: 4px 55px;
    margin:auto;
    margin-top:1em;
  `}
`;

export interface RedPageThumbnailList {
  items: any;
  metadata: unknown;
}

const RedPageThumbnailList: React.FC<RedPageThumbnailList> = ({ items, metadata }) => {
  const categoryList = useSelector<NotesState, NotesState['categoryList']>(
    state => state.categoryList
  );
  const redPageList = useSelector<NotesState, NotesState['redPageList']>(
    state => state.redPageList
  );
  const dispatch = useDispatch();

  const loopWithSlice = () => {
    if (items && items['@nodes'].length > 3) {
      getJsonObject();
    } else {
    }
  };
  useEffect(() => {
    loopWithSlice();
  }, []);
  const getJsonObject = () => {
    const nodesListSplited = [];
    nodesListSplited.push(items['@nodes'][0]);
    nodesListSplited.push(items['@nodes'][1]);
    nodesListSplited.push(items['@nodes'][2]);
    for (const key of Object.keys(items)) {
      if (items[key] instanceof Object && nodesListSplited.includes(key)) {
        for (const key1 of Object.keys(items[key])) {
          if (key1 === 'selectedRedPage') {
            //get first three items and push them to redux store
            dispatch(addRedPage(items[key][key1]));
          }
        }
      }
    }
    return items;
  };

  function handleShowMorePosts() {
    dispatch(removeRedPage(true));
    setTimeout(function () {
      window.scroll({
        top: document.body.scrollHeight - 1500,
        left: 0,
        behavior: 'smooth',
      });
    }, 2);
  }
  function handleShowMorePostsMobile() {
    dispatch(removeRedPage(true));
    setTimeout(function () {
      window.scroll({
        top: document.body.scrollHeight - 800,
        left: 0,
        behavior: 'smooth',
      });
    }, 2);
  }

  return (
    <>
      {items && (
        <Base>
          <EditableArea content={items} parentTemplateId={metadata['mgnl:template']} />
        </Base>
      )}
      <div>
        {redPageList.length === 0 ||
          categoryList.length > 0 ||
          (items['@nodes'] && (
            <div>
              <DesktopWrapper>
                <StyledButton onClick={handleShowMorePosts}>LOAD MORE</StyledButton>
              </DesktopWrapper>
              <MobileWrapper>
                <StyledButton onClick={handleShowMorePostsMobile}>LOAD MORE</StyledButton>
              </MobileWrapper>
            </div>
          ))}
      </div>
    </>
  );
};
export default RedPageThumbnailList;
