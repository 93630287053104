import React, { useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { getAPIBase } from '../helpers/AppHelpers';
import mediaQueries, { SIZES } from '../utils/mediaQueries';
import { fluidProperty, selectColor, selectFont } from '../utils/themeUtils';
import arrowRight from '../assets/arrow_right.svg';
import { Link } from 'react-router-dom';
import { followCursor } from '../utils/followCursor';

const StyledContainer = styled.div<{ alignRight: string }>`
  ${props => (props.alignRight === 'true' ? 'margin-left' : 'margin-right')}: 33%;
  ${mediaQueries.desktop`
       ${props => (props.alignRight === 'true' ? 'margin-left' : 'margin-right')}:
       calc(4% + 390 * ((100vw - ${SIZES.mobile}px) / ${SIZES.desktop - SIZES.mobile}));
    `}
  ${mediaQueries.mobile`
    ${props => (props.alignRight === 'true' ? 'margin-left' : 'margin-right')}: 0;
    `}
`;
const StyledImage = styled.img<{ alignRight: string }>`
  cursor: none;
  width: 100%;
  ${props => (props.alignRight === 'true' ? 'margin-right' : 'margin-left')}: 0;
  ${mediaQueries.mobile`
    ${props => (props.alignRight === 'true' ? 'margin-right' : 'margin-left')}: -1.5em;
    width: calc(100% + 1.5em);
  `}
`;

const fadeIn = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

const StyledHoverCircle = styled.div`
  display: none;
  position: absolute;
  ${fluidProperty('width', 200, 200)}
  ${fluidProperty('height', 200, 200)}
  background-color: ${selectColor('dark')};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.5s linear;

  ${mediaQueries.mobile`
    display: none !important;
  `}
`;

const StyledHoverCircleMobile = styled(StyledHoverCircle)`
  ${mediaQueries.mobile`
    display: flex !important;
    width: 150px;
    height: 150px;
    top: calc(50% - 75px);;
    left: 10%;
    animation: none;
  `}
`;

const StyledCircleText = styled.p`
  ${selectFont('projectHoverCircleText')}
  width: 100%;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  ${mediaQueries.mobile`
    padding: 8px;
  `}
`;
styled(Link);
const StyledArrow = styled.img`
  left: 25%;
  position: absolute;
  bottom: -25px;
  ${mediaQueries.mobile`
    width: 50px;
    left:30%;
    bottom: -10px;
  `}
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 1em 0 2.5em 0;
  ${mediaQueries.mobile`
    flex-direction: column;
  `}
`;
const StyledTitle = styled.h3`
  ${selectFont('projectThumbnailTitle')}
  flex: 1;
  padding-right: 1em;
`;
const StyledText = styled.div`
  ${selectFont('projectThumbnailText')}
  color: ${selectColor('grey')};
  flex: 2;
`;
const StyledLink = styled(Link)`
  ${mediaQueries.mobile`
    position: relative;
    display: block;
  `}
`;

export interface HoverCircleProps {
  hoverCircleText: string;
}

export interface ProjectThumbnailProps {
  selectedProject: string;
  alignRight: string;
}

const ProjectThumbnail: React.FC<ProjectThumbnailProps> = ({ selectedProject, alignRight }) => {
  const [project, setProject] = useState(null);
  const containerRef = useRef(null);
  const followIconRef = useRef(null);

  React.useEffect(() => {
    async function fetchProject() {
      const response = await fetch(
        getAPIBase() + process.env.REACT_APP_MGNL_API_PROJECTS + '?@jcr:uuid=' + selectedProject
      );
      const data = await response.json();
      setProject(data.results.pop());
      followCursor(followIconRef.current, containerRef.current);
    }
    fetchProject();
  }, [selectedProject]);

  return (
    <StyledContainer alignRight={alignRight}>
      {project && (
        <StyledLink key={project['@id']} to={project.page ? project.page['@path'] : ''}>
          <StyledHoverCircle ref={followIconRef}>
            {project.hoverCircleText ? (
              <StyledCircleText>{project.hoverCircleText}</StyledCircleText>
            ) : (
              ''
            )}
            <StyledArrow src={arrowRight} alt="arrow right" />
          </StyledHoverCircle>
          <StyledHoverCircleMobile>
            {project.hoverCircleText ? (
              <StyledCircleText>{project.hoverCircleText}</StyledCircleText>
            ) : (
              ''
            )}
            <StyledArrow src={arrowRight} alt="arrow right" />
          </StyledHoverCircleMobile>
          <StyledImage
            ref={containerRef}
            alignRight={alignRight}
            src={project.image['@link']}
            alt={project.name}
          />
        </StyledLink>
      )}
      <TextContainer>
        <StyledTitle>{project?.name}</StyledTitle>
        <StyledText>{project?.description}</StyledText>
      </TextContainer>
    </StyledContainer>
  );
};

export default ProjectThumbnail;
