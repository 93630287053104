import React, { useState } from 'react';
import styled from 'styled-components';
import { Font, Color, ThemeKeyOrFunc } from '../config/theme';
import { selectColor, selectFont } from '../utils/themeUtils';
import mediaQueries from '../utils/mediaQueries';

type StyleProps = {
  size: ThemeKeyOrFunc<Font>;
  fontColor?: ThemeKeyOrFunc<Color>;
  width: string;
};

const StyledSectionTitle = (semanticHeading?: 'h2' | 'h3' | 'h4') =>
  styled(semanticHeading)<StyleProps>`
    width: ${props => props.width};
    color: ${props => selectColor(props.fontColor)};
    ${props => selectFont(props.size)};

    ${mediaQueries.mobile`
      width: 100%;
    `}
  `;

export interface SectionTitleProps {
  sectionTitle: string;
  size: ThemeKeyOrFunc<Font>;
  color: ThemeKeyOrFunc<Color>;
  anchorLinkId?: string;
  width?: string;
  semanticHeading?: 'h2' | 'h3' | 'h4';
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  sectionTitle,
  size,
  color,
  anchorLinkId,
  width,
  semanticHeading,
}) => {
  const [SemanticSectionTitle, setSemanticSectionTitle] = useState(null);

  React.useEffect(() => {
    setSemanticSectionTitle(StyledSectionTitle(semanticHeading || 'h4'));
  }, [semanticHeading]);

  return (
    <div>
      {SemanticSectionTitle ? (
        <SemanticSectionTitle fontColor={color} size={size} id={anchorLinkId} width={width}>
          {sectionTitle}
        </SemanticSectionTitle>
      ) : (
        <div />
      )}
    </div>
  );
};

export default SectionTitle;
