import React from 'react';
import styled from 'styled-components';
import arrowDown from '../assets/arrow_down.svg';
import mediaQueries from '../utils/mediaQueries';
import { selectColor, selectFont } from '../utils/themeUtils';

const StyledListItem = styled.li`
  position: relative;
  display: inline;
  margin-right: 2rem;
  line-height: 7rem;
  ${mediaQueries.mobile`
    position: unset;
    display: block;
  `}
`;

const StyledJumpLink = styled.a`
  cursor: pointer;
  user-select: none;
  ${selectFont('jumpLink')}
  transition: color: 0.25s ease-out;
  &:hover {
    color: ${selectColor('grey')};
  }
  &:hover img {
    transform: scaleY(1);
  }
`;

const StyledArrow = styled.img`
  position: absolute;
  top: 0.5em;
  left: 49%;
  pointer-events: none;
  transition: transform 0.25s ease-out;
  transform: scaleY(0);
  transform-origin: top;
  ${mediaQueries.mobile`
    top: unset;
    left: unset;
    right: 0.25em;
    bottom: -1em;
  `}
`;

export interface JumpLinkProps {
  label: string;
  anchorLinkId: string;
}

const JumpLink: React.FC<JumpLinkProps> = ({ label, anchorLinkId }) => {
  function jumpToLink() {
    document.getElementById(anchorLinkId)?.scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <StyledListItem>
      <StyledJumpLink onClick={() => jumpToLink()}>
        {label}
        <StyledArrow src={arrowDown} alt="arrow down" aria-hidden="true" />
      </StyledJumpLink>
    </StyledListItem>
  );
};

export default JumpLink;
