import { css, CSSObject, Interpolation, ThemedStyledProps } from 'styled-components';
import sizes from '../config/theme/sizes';

export const SIZES = {
  bigScreen: sizes.bigScreenWidth, // 156 em
  desktop: sizes.desktopWidth, // 90em
  mobile: sizes.mobileWidth, // 37em
};

// Iterate through the sizes and create a media template
export default Object.keys(SIZES).reduce(
  (acc, label): { bigScreen: number; desktop: number; mobile: number } => {
    acc[label] = (first, ...args) => css`
      @media (max-width: ${SIZES[label] / 16}em) {
        ${css(first, ...args)}
      }
    `;

    return acc;
  },
  { bigScreen: null, desktop: null, mobile: null }
);

export const ie11 = (
  first: CSSObject,
  ...args: Interpolation<ThemedStyledProps<unknown, unknown>>[]
): unknown => css`
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    ${css(first, ...args)}
  }
`;
