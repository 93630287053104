import React from 'react';

export interface ArrowRightIconProps {
  style?: Record<string, unknown>;
}

const ArrowRightIcon: React.FC<ArrowRightIconProps> = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.396"
    height="15.557"
    viewBox="0 0 21.396 15.557"
    style={style}
  >
    <g id="Group_241" data-name="Group 241" transform="translate(-930.018 -722.115)">
      <path
        id="Path_2528"
        data-name="Path 2528"
        d="M10252,744h10v10"
        transform="translate(-5780.243 -7052.524) rotate(45)"
        fill="none"
        stroke="#206384"
        strokeWidth="2"
      />
      <path
        id="Path_2529"
        data-name="Path 2529"
        d="M10276.982,731.894H10257"
        transform="translate(-9326.982 -2)"
        fill="none"
        stroke="#206384"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default ArrowRightIcon;
