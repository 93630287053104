import React, { useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { getAPIBase } from '../helpers/AppHelpers';
import mediaQueries, { SIZES } from '../utils/mediaQueries';
import { selectColor, selectFont } from '../utils/themeUtils';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
`;

styled(Link);

const SlickSlide = styled.div`
  position: relative;
  height: 50%;
`;
const StyledLink = styled(Link)`
  ${mediaQueries.mobile`
    position: relative;
    display: block;
  `}
`;
const StyledMainTitle = styled.h4`
  color: ${selectColor('grey')};
  padding-bottom: 0.5em;
  ${selectFont('textMedium')}
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
const MainThumbnail = styled.div`
  ${mediaQueries.mobile`
  padding-left:2em;
  `}
`;
const StyledCategory = styled.span``;
const StyledDate = styled.span`
  padding-left: 1em;
`;

const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;
const StyledCategoryAndDate = styled.div`
  ${selectFont('textExtraTiny')}
  color: ${selectColor('grey')};
`;

const StyledMainDescription = styled.div`
  ${selectFont('textSmall')}
`;

export interface HoverCircleProps {
  hoverCircleText: string;
}

export interface RedPageThumbnailAProps {
  selectedRedPage: string;
  title: string;
  description: string;
}

const RedPageThumbnailA: React.FC<RedPageThumbnailAProps> = ({
  selectedRedPage,
  title,
  description,
}) => {
  const [project, setProject] = useState(null);
  const [category, setCategory] = useState(null);
  const containerRef = useRef(null);

  React.useEffect(() => {
    async function fetchProject() {
      const response = await fetch(
        getAPIBase() + process.env.REACT_APP_MGNL_API_RED_PAGES + '?@jcr:uuid=' + selectedRedPage
      );
      const data = await response.json();
      setProject(data.results.pop());
    }

    fetchProject();
  }, [selectedRedPage]);
  React.useEffect(() => {
    async function fetchCategory(selectedCategory) {
      const response = await fetch(
        getAPIBase() + process.env.REACT_APP_MGNL_API_CATEGORIES + '?@jcr:uuid=' + selectedCategory
      );
      const data = await response.json();
      setCategory(data.results.pop());
    }

    if (project != null) {
      fetchCategory(project.page['category']);
    }
  }, [project]);

  return (
    <React.Fragment>
      <MainThumbnail>
        {project && category && (
          <MainRow key={project['@id']}>
            <FirstColumn key={project['@id']}>
              <SlickSlide key={project['@id']}>
                {title !== undefined ? (
                  <StyledMainTitle key={project['@id']}>{title}</StyledMainTitle>
                ) : (
                  <StyledMainTitle style={{ visibility: 'hidden' }} key={project['@id']}>
                    EMPTY
                  </StyledMainTitle>
                )}
                <StyledLink key={project['@id']} to={project.page ? project.page['@path'] : ''}>
                  <StyledImage ref={containerRef} src={project.image['@link']} alt={project.name} />
                </StyledLink>
                <StyledMainDescription
                  key={project['@id']}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></StyledMainDescription>
                <StyledCategoryAndDate key={project['@id']}>
                  <StyledCategory>{category['@name']}</StyledCategory>
                  <StyledDate>
                    <Moment format="ll">{project.page['date']}</Moment>
                  </StyledDate>
                </StyledCategoryAndDate>
              </SlickSlide>
            </FirstColumn>
          </MainRow>
        )}
      </MainThumbnail>
    </React.Fragment>
  );
};

export default RedPageThumbnailA;
