export function getAPIBase(): string {
  let M;
  if (
    process.env.REACT_APP_MGNL_IS_PREVIEW &&
    typeof window !== 'undefined' &&
    window.location.href?.includes('/author/')
  ) {
    M = process.env.REACT_APP_MGNL_BASE_AUTHOR;
  } else {
    M = process.env.REACT_APP_MGNL_BASE_PUBLIC;
  }
  return process.env.REACT_APP_MGNL_HOST + M;
}

export function getLanguages(): string[] {
  return process.env.REACT_APP_MGNL_LANGUAGES.split(' ');
}

export function removeCurrentLanguage(string: string, currentLanguage: string): string {
  return string.replace(new RegExp(`/${currentLanguage}($|/)`), '/');
}

export function getCurrentLanguage(): string {
  const languages = getLanguages();

  for (let i = 0; i < languages.length; i++) {
    const language = languages[i];

    if (new RegExp(`/${language}($|/)`).test(window.location.pathname)) {
      return language;
    }
  }

  return languages[0];
}

export function changeLanguage(newLanguage: string): void {
  const nodeName = process.env.REACT_APP_MGNL_APP_BASE;
  const languages = getLanguages();
  let { pathname } = window.location;
  const currentLanguage = getCurrentLanguage();

  pathname = removeCurrentLanguage(pathname, currentLanguage).replace(
    process.env.REACT_APP_MGNL_BASE_AUTHOR,
    ''
  );

  if (languages[0] !== newLanguage) {
    if (pathname.indexOf(nodeName) > -1) {
      pathname = '/english';
    } else {
      pathname = `/${newLanguage}${pathname}`;
    }
  }

  window.location.href =
    window.location.origin +
    (isAuthorContext() ? process.env.REACT_APP_MGNL_BASE_AUTHOR : '') +
    pathname +
    window.location.search;
}

export function isAuthorContext(): boolean {
  return window.location.pathname.indexOf(process.env.REACT_APP_MGNL_BASE_AUTHOR) > -1;
}

export function getRouterBasename(): string {
  const languages = getLanguages();
  const { pathname } = window.location;
  const currentLanguage = getCurrentLanguage();

  const languagePart = languages[0] === currentLanguage ? '/' : `/${currentLanguage}`;
  const authorPart =
    pathname.indexOf(process.env.REACT_APP_MGNL_BASE_AUTHOR) > -1
      ? process.env.REACT_APP_MGNL_BASE_AUTHOR
      : '';
  return authorPart + languagePart;
}
