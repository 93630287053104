import React from 'react';
import styled from 'styled-components';
import mediaQueries from '../utils/mediaQueries';
import { Link } from 'react-router-dom';

const StyledImg = styled.img`
  max-height: 160px;
  max-width: 320px;
  padding: 1em 0.5em 0 0;
  ${mediaQueries.bigScreen`
    width: 320px;
  `}
  ${mediaQueries.desktop`
    width: 80%;
  `}
  ${mediaQueries.mobile`
    width: 100%;
  `}
`;

const StyledExternalLink = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const StyledInternalLink = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`;

export interface Link {
  field?: string;
  external?: string;
  internal?: string;
}

export interface FooterLogoProps {
  image: { '@link': string };
  title: string;
  link: Link;
}

const FooterLogo: React.FC<FooterLogoProps> = ({ image, title, link }) => {
  if (image && image['@link'].length > 0) {
    if (link && link.field === 'external') {
      return (
        <StyledExternalLink href={link.external} target="_blank" rel="noreferrer">
          <StyledImg src={image['@link']} alt={title} />
        </StyledExternalLink>
      );
    } else if (link && link.field === 'internal') {
      return (
        <StyledInternalLink to={link.internal['@path']}>
          <StyledImg src={image['@link']} alt={title} />
        </StyledInternalLink>
      );
    } else {
      return <StyledImg src={image['@link']} alt={title} />;
    }
  } else {
    return null;
  }
};

export default FooterLogo;
