import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { fetchPage } from '../helpers/FetchPage';
import { selectColor, selectFont } from '../utils/themeUtils';
import { Link } from 'react-router-dom';

const StyledItem = styled(Link)<{ active: string }>`
  color: ${selectColor('grey')};
  ${selectFont('textTiny')}
  margin-right: 2em;
  display: inline-block;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  ${props =>
    props.active === 'true'
      ? css`
          border-bottom-color: ${selectColor('primary')};
        `
      : ''}

  &:hover {
    border-bottom-color: ${selectColor('primary')};
  }
`;

export interface CategoryItemProps {
  category: { displayName: string };
  link: string;
  active: string;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ category, link, active }) => {
  const [internalLink, setInternalLink] = useState(null);
  useEffect(() => {
    const getInternalLinkPath = async pageId => {
      const page = await fetchPage(pageId);
      setInternalLink(page['@path']);
    };
    if (link) {
      getInternalLinkPath(link);
    }
  }, [link]);
  return (
    <StyledItem to={internalLink} active={active}>
      {category?.displayName}
    </StyledItem>
  );
};

export default CategoryItem;
