import { addCategoryList, addRedPage, removeCategoryList, removeRedPage } from './actions';

export interface NotesState {
  categoryList: string[];
  redPageList: string[];
  redPageBoolean: boolean;
}

const initialState = { categoryList: [], redPageList: [], redPageBoolean: false };
type ProductAction = ReturnType<
  typeof addCategoryList | typeof removeCategoryList | typeof addRedPage | typeof removeRedPage
>;

export const notesReducer = (state: NotesState = initialState, action: ProductAction) => {
  switch (action.type) {
    case 'ADD_CATEGORY': {
      return { ...state, categoryList: [...state.categoryList, action.payload.toLowerCase()] };
    }
    case 'REMOVE_CATEGORY': {
      return {
        ...state,
        categoryList: state.categoryList.filter(val => val != action.payload.toLowerCase()),
      };
    }
    case 'ADD_RED_PAGE': {
      return { ...state, redPageList: [...state.redPageList, action.payload] };
    }
    case 'REMOVE_RED_PAGE': {
      return {
        ...state,
        redPageList: [],
      };
    }

    default:
      return state;
  }
};
