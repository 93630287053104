import React, { useRef } from 'react';
import styled from 'styled-components';
import { EditableArea } from '@magnolia/react-editor';
import mediaQueries from '../utils/mediaQueries';
import { selectSize } from '../utils/themeUtils';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import next from '../assets/arrow_next.svg';
import previous from '../assets/arrow_previous.svg';
import nextHover from '../assets/arrow_next_primary.svg';
import previousHover from '../assets/arrow_previous_primary.svg';
import {
  firstItem,
  listItemsDesktop,
  listItemsMobile,
} from '../helpers/renderRedPageThumbnailGallery';
import {
  getAllItems,
  getAllItemsWithoutFirst,
  getFirstItem,
} from '../helpers/fetchItemsRedPageThumbnailGallery';

const Base = styled.ul`
  margin-bottom: ${selectSize('spacerMedium')}em;
  ${mediaQueries.mobile`
    width: 50%;
    margin-bottom: 0;
  `}
  > div:first-of-type {
    padding-right: 8px;
  }
`;

const StyledArrow = styled.div`
  display: inline;
  position: absolute;
  z-index: 999;
  width: auto;
  right: 50px;
  ${mediaQueries.mobile`
display:none;
  `}
`;
const FirstNodeDiv = styled.div`
div:nth-child(1) {
  padding-left:0!important;
`;

const StyledArrowMobile = styled.div`
  ${mediaQueries.mobile`
    position: absolute;
    z-index: 999;
    width: auto;
    right: 14px;
    display:inline;
  `}
  display:none;
`;
const SliderWrapper = styled.div`
  width: 100%;
  margin: auto;
  ${mediaQueries.mobile`
    display: none;
  `}
`;
const SliderWrapperMobile = styled.div`
  width: 100%;
  margin: auto;
  ${mediaQueries.mobile`
          margin-bottom:0em;
  `}
  @media screen and (min-width: 593px) {
    display: none !important;
  }
`;

const StyledButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  margin-left: 10px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const StyledIconImg = styled.img`
  ${StyledButton}:hover & {
    display: none;
  }
  &:focus {
    outline: none;
  }
`;

const StyledIconImgHover = styled(StyledIconImg)`
  display: none;
  ${StyledButton}:hover & {
    display: flex;
  }
`;

const StyledImageScrollerMobile = styled(Slider)`
  height: 30vh !important;
  outline: none;
  margin-left: calc(-50vw + 50%);
  width: 100vw;
  display: none;

  & div.slick-slide {
    opacity: 0.5;
    padding-right: 10px;
    &.slick-active {
      opacity: 1;
    }
  }

  > ul:first-of-type {
    bottom: 0px !important;
  }

  ${mediaQueries.mobile`
    display: block;
     > ul:first-of-type {
    bottom: auto !important;
  }
  `}
`;

export interface RedPageThumbnailGallery {
  items: any;
  metadata: unknown;
}

const RedPageThumbnailGallery: React.FC<RedPageThumbnailGallery> = ({ items, metadata }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    variableWidth: false,
    adaptiveHeight: false,
  };
  const settingsMoblie = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    variableWidth: false,
    adaptiveHeight: false,
  };

  const arrayOfNodes: any = [];
  const firstNode: any = [];
  const eliminateFirstNode: any = [];

  const nodesListSplited = items['@nodes'];

  //split every item to separete json object
  const objectNested = {};
  const firstObjectNested = {};
  const eliminateFirstObjectNested = {};

  getAllItems(items, nodesListSplited, objectNested, arrayOfNodes);
  getFirstItem(items, nodesListSplited, firstObjectNested, firstNode);
  getAllItemsWithoutFirst(items, nodesListSplited, eliminateFirstObjectNested, eliminateFirstNode);

  const slider = useRef(null);
  const sliderMobile = useRef(null);

  return (
    <>
      <SliderWrapper>
        <StyledArrow>
          <StyledButton onClick={() => slider.current.slickPrev()}>
            <StyledIconImg src={previous} alt="" />
            <StyledIconImgHover src={previousHover} alt="" />
          </StyledButton>
          <StyledButton onClick={() => slider.current.slickNext()}>
            <StyledIconImg src={next} alt="" />
            <StyledIconImgHover src={nextHover} alt="" />
          </StyledButton>
        </StyledArrow>
        <Slider {...settings} ref={slider}>
          {arrayOfNodes.length === 0 && items && (
            <Base>
              <EditableArea content={items} parentTemplateId={metadata['mgnl:template']} />
            </Base>
          )}
          {arrayOfNodes.length > 0 && listItemsDesktop(arrayOfNodes, Base, EditableArea, metadata)}
        </Slider>
      </SliderWrapper>
      <SliderWrapperMobile>
        <FirstNodeDiv>
          {arrayOfNodes.length === 0 && items && (
            <Base>
              <EditableArea content={items} parentTemplateId={metadata['mgnl:template']} />
            </Base>
          )}
          {arrayOfNodes.length > 0 && firstItem(firstNode, Base, EditableArea, metadata)}
        </FirstNodeDiv>
        <StyledArrowMobile>
          <StyledButton onClick={() => sliderMobile.current.slickPrev()}>
            <StyledIconImg src={previous} alt="" />
            <StyledIconImgHover src={previousHover} alt="" />
          </StyledButton>
          <StyledButton onClick={() => sliderMobile.current.slickNext()}>
            <StyledIconImg src={next} alt="" />
            <StyledIconImgHover src={nextHover} alt="" />
          </StyledButton>
        </StyledArrowMobile>
        <StyledImageScrollerMobile {...settingsMoblie} ref={sliderMobile}>
          {arrayOfNodes.length === 0 && items && (
            <Base>
              <EditableArea content={items} parentTemplateId={metadata['mgnl:template']} />
            </Base>
          )}
          {arrayOfNodes.length > 0 &&
            listItemsMobile(eliminateFirstNode, Base, EditableArea, metadata)}
        </StyledImageScrollerMobile>
      </SliderWrapperMobile>
    </>
  );
};
export default RedPageThumbnailGallery;
