import React from 'react';
import styled from 'styled-components';
import { Color, Font, ThemeKeyOrFunc } from '../config/theme';
import mediaQueries from '../utils/mediaQueries';
import { selectColor, selectFont, selectSize } from '../utils/themeUtils';

type StyleProps = {
  width: string;
  size: ThemeKeyOrFunc<Font>;
  fontColor?: ThemeKeyOrFunc<Color>;
};

const StyledParagraph = styled.div<StyleProps>`
  color: ${props => selectColor(props.fontColor)};
  width: ${props => props.width};
  ${mediaQueries.mobile`
    width: 100%;
    padding-right: 0;
  `}
  ${props => selectFont(props.size)};

  p > a {
    color: inherit;
    text-decoration: none;
    border-bottom: ${selectSize('underlineWidth')} solid ${selectColor('primary')};

    &:hover {
      color: ${selectColor('grey')};
    }
  }

  p > ul {
    margin: 0.5em 0;
    > li {
      margin-left: 1.5em;
      list-style-type: disc;
    }
  }
`;

export interface ParagraphProps {
  richText: string;
  size?: ThemeKeyOrFunc<Font>;
  width?: string;
  color: ThemeKeyOrFunc<Color>;
}

const Paragraph: React.FC<ParagraphProps> = ({ richText, size, width, color }) => {
  return (
    <StyledParagraph fontColor={color} size={size} width={width}>
      <p className="Paragraph" dangerouslySetInnerHTML={{ __html: richText }} />
    </StyledParagraph>
  );
};

export default Paragraph;
