import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import styled from 'styled-components';
import mediaQueries from '../utils/mediaQueries';
import { selectSize } from '../utils/themeUtils';

const Base = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${mediaQueries.mobile`
    flex-direction: column;
  `}
`;

const StyledEmptyColumn = styled.div`
  flex: 1;
`;

const StyledColumn1 = styled.div`
  flex: 1;
  margin-bottom: ${selectSize('spacerSmall')}em;
  ${mediaQueries.mobile`
    padding-right: 0;
  `}
  > div {
    ${mediaQueries.mobile`
      display: flex;
      flex-wrap: wrap;
    `}
  }
`;

const StyledColumn2 = styled.div`
  flex: 1;
  ${mediaQueries.mobile`
    display: flex;
    flex-direction: column;
    padding-right: 0;
    width: 100%;
  `}
  > div {
    ${mediaQueries.mobile`
      display: flex;
      flex-wrap: wrap;
    `}
  }
`;

const StyledUpperColumn2 = styled.div`
  > div {
    ${mediaQueries.mobile`
      width: 100%;
    `}
  }
`;

const StyledLowerColumn2 = styled.div`
  > div {
    ${mediaQueries.mobile`
      display: flex;
      width: 100%;
      margin-bottom: ${selectSize('spacerMedium')}em;
    `}
  }
`;

export interface FooterProps {
  column1: unknown;
  upperColumn2: unknown;
  lowerColumn2: unknown;
  metadata: unknown;
}

const Footer: React.FC<FooterProps> = ({ column1, upperColumn2, lowerColumn2, metadata }) => {
  return (
    <Base>
      <StyledEmptyColumn />
      <StyledColumn1>
        {column1 && <EditableArea content={column1} parentTemplateId={metadata['mgnl:template']} />}
      </StyledColumn1>
      <StyledColumn2>
        <StyledUpperColumn2>
          {upperColumn2 && (
            <EditableArea content={upperColumn2} parentTemplateId={metadata['mgnl:template']} />
          )}
        </StyledUpperColumn2>
        <StyledLowerColumn2>
          {lowerColumn2 && (
            <EditableArea content={lowerColumn2} parentTemplateId={metadata['mgnl:template']} />
          )}
        </StyledLowerColumn2>
      </StyledColumn2>
    </Base>
  );
};

export default Footer;
