import { useEffect, useState } from 'react';
import { fetchPage } from '../helpers/FetchPage';

export function usePage<T>(pageId: string): T {
  const [page, setPage] = useState(null);

  useEffect(() => {
    fetchPage(pageId).then(page => setPage(page));
  }, [pageId]);

  return page;
}
