import React from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';
import { usePage } from '../hooks/usePage';
import { RedPageProps } from '../pages/Red';
import mediaQueries from '../utils/mediaQueries';
import { selectColor, selectFont, selectSize } from '../utils/themeUtils';
import { Link } from 'react-router-dom';
import articlePlaceholder from '../assets/article-placeholder.svg';

const StyledLink = styled(Link)`
  display: block;
  margin-top: 4em;
  margin-right: 0.5em;
  ${mediaQueries.mobile`
    margin-top: 1em;
  `}
`;

const StyledImg = styled.img`
  height: 10em;
  min-height: 220px;
  width: 100%;
  object-fit: cover;
`;

const StyledTitle = styled.h3`
  ${selectFont('articleLinkTitle')}
  margin-top: ${selectSize('paddingSmall')}em;
  margin-bottom: ${selectSize('paddingTiny')}em;
`;

const InfoContainer = styled.div`
  display: flex;
  color: ${selectColor('grey')};
  ${selectFont('articleLinkInfo')}
`;

const StyledCategory = styled.div`
  margin-right: ${selectSize('paddingMedium')}em;
`;

export interface ArticleLinkProps {
  page: string;
}

const ArticleLink: React.FC<ArticleLinkProps> = ({ page }) => {
  const articlePage = usePage<RedPageProps>(page);

  return (
    <StyledLink to={articlePage ? articlePage['@path'] : ''}>
      <StyledImg src={articlePage?.image ? articlePage?.image['@link'] : articlePlaceholder} />
      <StyledTitle>{articlePage?.redTitle}</StyledTitle>
      <InfoContainer>
        {articlePage?.category && (
          <StyledCategory>{articlePage?.category.displayName}</StyledCategory>
        )}
        <div>
          <Moment format="ll">{articlePage?.date}</Moment>
        </div>
      </InfoContainer>
    </StyledLink>
  );
};

export default ArticleLink;
