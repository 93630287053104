import React from 'react';
import StyledButton from './StyledButton';

export interface BtnProps {
  style?: unknown;
  className?: string;
  buttonText: string;
  link: {
    field?: string;
    external?: string;
    internal?: string;
  };
}

const Button: React.FC<BtnProps> = ({
  /* style,
  className, */
  buttonText,
  link,
}) => {
  if (!link.field || link.field === 'none') {
    return <StyledButton as="a">{buttonText}</StyledButton>;
  }
  return link.field === 'external' ? (
    <StyledButton as="a" href={link.external} target="_blank" rel="noreferrer">
      {buttonText}
    </StyledButton>
  ) : (
    <StyledButton as="a" href={link.internal['@path']}>
      {buttonText}
    </StyledButton>
  );
};

export default Button;
