import React, { useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/RZ_panter_logo_RGB_negativ.svg';
import threeDots from '../assets/three-dots.svg';
import { getAPIBase } from '../helpers/AppHelpers';
import mediaQueries from '../utils/mediaQueries';
import { fluidProperty, selectColor, selectFont, selectSize } from '../utils/themeUtils';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const HOME_LINK = '/home';
const DENTAO_LINK = '/szd-denteo';
type StyleProps = {
  show: boolean;
  hideOnMobile: boolean;
  isMenuCollapsed: boolean;
  mobileMenuHeight: number;
};
const StyledLink = styled(NavLink)`
  border-bottom: ${selectSize('underlineWidth')} solid transparent;
  transition: color 0.25s linear;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  margin: 0 ${selectSize('paddingMedium')}em;
  ${selectFont('titleMedium')}
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${selectColor('primary')};
    position: absolute;
    left: 0;
    bottom: -0.5em;
    transform-origin: left;
    transform: scaleX(0);
    transition: 0.25s ease-out;
  }

  &:visited,
  & {
    color: ${selectColor('light')};
  }

  &:hover,
  &.active:not(.mobile) {
    color: ${selectColor('grey')};
  }

  &:hover:not(.mobile):before,
  &.active:not(.mobile):before {
    transform: scaleX(1);
  }
  &.mobile {
    display: inline;
  }
  ${mediaQueries.mobile`
    :not(.mobile) {
      margin: 0 0 0 5em;
    }
    .mobile {
      margin: 0;
    }
    &:not(.active):not(.mobile) {
      display: none;
    }
  `}
`;
const StyledNav = styled('nav')<StyleProps>`
  position: fixed;
  width: 100%;
  min-height: 43px;
  padding: ${selectSize('paddingSmall')}em 0;
  ${mediaQueries.mobile`
  top: ${props => (props.hideOnMobile ? '-70px' : '-1px')};
    padding-top: 1.5em;
    transition: top 0.3s;
    
  height:${props => props.mobileMenuHeight + 'px'};
      background:rgb(27, 28, 30);
   
  `}
  z-index: 100;
  top: 0px;
  left: 0px;
  background: ${props => (props.show ? 'transparent' : 'rgb(27, 28, 30);')};
`;

const StyledNavLinks = styled('div')`
  position: relative;
  left: 34.33%;
  > * {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  ${mediaQueries.mobile`
    display: flex;
    justify-content: center;
    left: 0;
  `}
`;

const StyledLogo = styled('img')`
  position: absolute;
  z-index: 100;
  object-fit: cover;
  object-position: left;
  //padding: 7px 0;
  width: auto;
  margin-top: 10px;
  left: 1.5em;
  ${fluidProperty('height', 20, 33)}
`;

const StyledButton = styled.button`
  display: none;
  ${mediaQueries.mobile`
    display: inline;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: none;
    text-align: right;
    position: absolute;
    z-index: 999;
    width: auto;
    right: 14px;
    top: 23px;
  `}
`;

const MobileMenu = styled.div`
  background-color: ${selectColor('dark')};
  overflow: hidden;
  display: none;
  transition: all 0.25s ease-out;
  text-align: left;
  padding: 0.5em 1em;
  margin-right: -1.5rem;
  width: 60%;
  max-height: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  > div:first-of-type {
    margin-top: 1em;
  }
  > div:last-of-type {
    margin-bottom: 1em;
  }

  ${mediaQueries.mobile`
    display: inline;
  `}
`;

function Navigation(): JSX.Element {
  const [navItems, setNavItems] = React.useState([]);
  const [hideLogo, setHideLogo] = React.useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = React.useState(true);
  const [hideOnScroll, setHideOnScroll] = React.useState(true);
  const [hideOnMobile, setHideOnMobile] = React.useState(false);
  const [heightMobileMenu, setHeightMobileMenu] = React.useState(60);
  const [hideNavigation, setHideNavigation] = React.useState(false);

  useScrollPosition(
    ({ currPos, prevPos }) => {
      if (prevPos.y >= -70 || currPos.y >= -70) {
        setHideOnMobile(false);
      } else {
        if (prevPos.y < currPos.y) {
          if (prevPos.y == 0 || currPos.y == 0) {
            setHideOnMobile(false);
          }
          setHideOnMobile(false);
        } else {
          setIsMenuCollapsed(true);
          setHideOnMobile(true);
        }
      }
      const isShow = currPos.y === 0;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  const ref = useRef(null);
  const navRef = useRef(null);

  const location = useLocation();
  document.onclick = ev => {
    if (!ev.target['contains'](ref.current)) {
      setIsMenuCollapsed(true);
    }
  };

  React.useEffect(() => {
    if (ref.current) {
      if (isMenuCollapsed) {
        ref.current.style.maxHeight = null;
        ref.current.style.opacity = 0;
        setHeightMobileMenu(60);
      } else {
        ref.current.style.maxHeight = ref.current.scrollHeight + 'px';
        setHeightMobileMenu(ref.current.scrollHeight);
        ref.current.style.opacity = 1;
      }
    }
  }, [isMenuCollapsed]);

  React.useEffect(() => {
    setHideLogo(location.pathname.indexOf(HOME_LINK) > -1);
    setHideNavigation(location.pathname.indexOf(DENTAO_LINK) > -1);
  }, [location]);

  React.useEffect(() => {
    async function fetchNav() {
      const apiBase = getAPIBase();
      const url =
        apiBase +
        process.env.REACT_APP_MGNL_API_NAV +
        process.env.REACT_APP_MGNL_APP_BASE +
        '?limit=10000';
      // console.log(`NAV URL:${url}`);
      const response = await fetch(url);
      const { results } = await response.json();
      // const aboutPage = results.filter(
      //   page => page.navigationTitle === 'About' && page.showInNavigation === 'true'
      // );
      // const solutionsPage = results.filter(
      //   page => page.navigationTitle === 'Solutions' && page.showInNavigation === 'true'
      // );
      // const servicesPage = results.filter(
      //   page => page.navigationTitle === 'Services' && page.showInNavigation === 'true'
      // );
      //
      // const allOthers = results.filter(
      //   page =>
      //     page.navigationTitle != 'About' &&
      //     page.navigationTitle != 'Solutions' &&
      //     page.navigationTitle != 'Services' &&
      //     page.showInNavigation === 'true'
      // );
      //
      // const listOfMandatoryPages: any = [];
      //
      // if (solutionsPage[0] != undefined) {
      //   listOfMandatoryPages.push(solutionsPage[0]);
      // }
      // if (servicesPage[0] != undefined) {
      //   listOfMandatoryPages.push(servicesPage[0]);
      // }
      // if (aboutPage[0] != undefined) {
      //   listOfMandatoryPages.push(aboutPage[0]);
      // }
      // const navPagesToShow = listOfMandatoryPages.concat(allOthers);
      setNavItems([...results]);
    }

    if (navItems.length < 1) {
      fetchNav();
    }
  }, [navItems]);

  return hideNavigation ? (
    <div />
  ) : navItems ? (
    <StyledNav
      show={hideOnScroll}
      ref={navRef}
      hideOnMobile={hideOnMobile}
      isMenuCollapsed={isMenuCollapsed}
      mobileMenuHeight={heightMobileMenu}
    >
      {hideLogo ? (
        <div />
      ) : (
        <NavLink to={HOME_LINK}>
          <StyledLogo src={logo} alt="panter logo" />
        </NavLink>
      )}
      {isMenuCollapsed ? (
        <StyledNavLinks>
          {navItems
            .filter(page => page.showInNavigation === 'true')
            .map(page => (
              <StyledLink
                activeClassName="active"
                key={page['@id']}
                to={page['@path'].replace(process.env.REACT_APP_MGNL_APP_BASE, '/')}
              >
                {page.navigationTitle}
              </StyledLink>
            ))}
        </StyledNavLinks>
      ) : (
        ''
      )}
      <StyledButton onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}>
        <img src={threeDots} alt="open mobile menu" />
      </StyledButton>
      <MobileMenu ref={ref}>
        {navItems
          .filter(page => page.showInNavigation === 'true')
          .map(page => (
            <div key={page['@id']}>
              <StyledLink
                className="mobile"
                to={page['@path'].replace(process.env.REACT_APP_MGNL_APP_BASE, '/')}
              >
                {page.navigationTitle}
              </StyledLink>
            </div>
          ))}
      </MobileMenu>
    </StyledNav>
  ) : (
    <div />
  );
}

export default Navigation;
