import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import { selectFont } from '../utils/themeUtils';
import styled from 'styled-components';

const StyledTitle = styled.h3`
  ${selectFont('titleSmall')}
`;

export interface LinkBoxProps {
  title: string;
  items: unknown;
  metadata: unknown;
}

const LinkBox: React.FC<LinkBoxProps> = ({ title, items, metadata }) => (
  <>
    <StyledTitle>{title}</StyledTitle>
    {items && <EditableArea content={items} parentTemplateId={metadata['mgnl:template']} />}
  </>
);

export default LinkBox;
