import { EditableArea } from '@magnolia/react-editor';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Column from '../components/Column';
import StyledButton from '../components/StyledButton';
import mediaQueries from '../utils/mediaQueries';
import { screenReaderOnly } from '../utils/screenReaderOnly';
import { selectFont } from '../utils/themeUtils';

const StyledJobTitle = styled.div`
  margin: 220px 0 120px;

  ${mediaQueries.mobile`
    margin: 150px 0 30px;
  `}
`;

const StyledMain = styled.div`
  display: flex;

  ${mediaQueries.mobile`
    flex-direction: column;
  `}
`;

const StyledColumn = styled(Column)`
  flex: 1 1 0%;
  ${mediaQueries.mobile`
    display: flex;
    justify-content: center;
  `}
`;
const AreaColumn = styled(Column)`
  flex: 2 1 0%;
`;

const StyledEmail = styled(StyledButton)`
  margin-top: 15px;
  padding: 8px 80px;
  max-height: 60px;

  ${mediaQueries.mobile`
    padding: 0 55px;
    margin-bottom: 35px;
  `}
`;

const StyledTitle = styled.h1`
  ${selectFont('titleLarge')}
  margin-bottom: 20px;
`;

const StyledSubTitle = styled.h2`
  ${selectFont('jobSubTitle')}
`;

const StyledEditableArea = styled(EditableArea)`
  width: 85%;

  ${mediaQueries.mobile`
    width: 100%;
  `}
`;

const ScreenReaderOnlyHeading1 = styled.h1`
  ${screenReaderOnly}
`;

interface JobPageProps {
  main?: unknown;
  metadata?: unknown;
  jobTitle: string;
  jobSubtitle: string;
  contactEmail: string;
  contactButtonText: string;
  windowTitle: string;
  title?: string;
  description?: string;
  keywords?: string;
  footer?: unknown;
  twitterImage: { '@link': string };
  openGraphImage: { '@link': string };
  ogUrl?: string;
  twitterUrl?: string;
  ogType?: string;
  ogTitle?: string;
  ogDescription?: string;
  twitterCard?: string;
  twitterTitle?: string;
  twitterDescription?: string;
}

const Job: React.FC<JobPageProps> = ({
  main,
  jobTitle,
  jobSubtitle,
  contactEmail,
  contactButtonText,
  windowTitle,
  title,
  twitterImage,
  openGraphImage,
  ogUrl,
  twitterUrl,
  ogType,
  ogTitle,
  ogDescription,
  twitterTitle,
  twitterDescription,
  twitterCard,
  description,
  keywords,
  footer,
}) => {
  let openGraphImgUrl, twitterImgUrl;

  if (openGraphImage != undefined || openGraphImage != null) {
    if (openGraphImage['@link'] != undefined) {
      openGraphImgUrl = 'https://www.panter.ch' + openGraphImage['@link'];
    }
  } else {
    openGraphImgUrl = '';
  }

  if (twitterImage != undefined || twitterImage != null) {
    if (twitterImage['@link'] != undefined) {
      twitterImgUrl = 'https://www.panter.ch' + twitterImage['@link'];
    }
  } else {
    twitterImgUrl = '';
  }
  return (
    <div>
      <Helmet>
        <title>{windowTitle}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <ScreenReaderOnlyHeading1 role="status" aria-live="polite" aria-atomic="true">
        {title}
      </ScreenReaderOnlyHeading1>

      <StyledJobTitle>
        {jobTitle && <StyledTitle>{jobTitle}</StyledTitle>}
        {jobSubtitle && <StyledSubTitle>{jobSubtitle}</StyledSubTitle>}
      </StyledJobTitle>

      <StyledMain>
        <StyledColumn size={1}>
          <StyledEmail as="a" href={'mailto:' + contactEmail}>
            {contactButtonText}
          </StyledEmail>
        </StyledColumn>
        <AreaColumn size={2}>{main && <StyledEditableArea content={main} />}</AreaColumn>
      </StyledMain>

      <footer className="Footer">
        {footer && <EditableArea className="Area" content={footer} />}
      </footer>
    </div>
  );
};

export default Job;
