import { css } from 'styled-components';
import { fluidProperty } from '../../utils/themeUtils';
import color from './colors';

export const FONT_FAMILY_POPPINS = '"Poppins", sans-serif';

const BASELINE = '1rem';

function fluidType(minFont: number, maxFont: number, minLineHeight: number, maxLineHeight: number) {
  return css`
    ${fluidProperty('font-size', minFont, maxFont)}
    ${fluidProperty('line-height', minLineHeight, maxLineHeight)}
  `;
}

export default {
  base: css`
    font-family: ${FONT_FAMILY_POPPINS};
    color: ${color.light};
    line-height: ${BASELINE};
    ${fluidType(10, 30, 20, 60)}
  `,
  titleLarge: css`
    ${fluidType(26, 60, 35, 80)}
  `,
  titleMedium: css`
    ${fluidType(18, 25, 33, 50)}
  `,
  titleSmall: css`
    ${fluidType(14, 18, 33, 40)}
  `,
  styledButtonTitle: css`
    ${fluidType(12, 18, 33, 40)}
  `,
  styledSubTitle: css`
    ${fluidType(16, 20, 29, 36)}
  `,
  titleTiny: css`
    ${fluidType(14, 15, 29, 32)}
  `,
  textLarge: css`
    ${fluidType(21, 40, 33, 65)}
  `,
  textMedium: css`
    ${fluidType(14, 20, 29, 36)}
  `,
  textSmall: css`
    ${fluidType(14, 18, 33, 40)}
  `,
  textTiny: css`
    ${fluidType(14, 15, 29, 32)}
  `,
  textExtraTiny: css`
    ${fluidType(10, 12, 32, 32)}
  `,
  projectThumbnailText: css`
    ${fluidType(14, 14, 25, 25)}
  `,
  projectThumbnailTitle: css`
    ${fluidType(16, 18, 32, 30)}
  `,
  jumpLink: css`
    ${fluidType(81, 100, 80, 104)}
  `,
  buttonText: css`
    ${fluidType(14, 16, 28, 40)}
  `,
  teamMember: css`
    ${fluidType(14, 25, 29, 50)}
  `,
  readMoreText: css`
    ${fluidType(14, 18, 30, 40)}
  `,
  projectLinkShortName: css`
    ${fluidType(40, 100, 47, 104)}
  `,
  headlineAnimation: css`
    ${fluidType(94, 170, 117, 240)}
  `,
  jobSubTitle: css`
    ${fluidType(14, 40, 29, 65)}
  `,
  projectHoverCircleText: css`
    ${fluidType(14, 20, 20, 30)}
  `,
  teamText: css`
    ${fluidType(14, 16, 28, 30)}
  `,
  projectDescription: css`
    ${fluidType(18, 22, 32, 46)}
  `,
  articleLinkTitle: css`
    ${fluidType(14, 18, 24, 30)}
  `,
  articleLinkInfo: css`
    ${fluidType(10, 14, 25, 25)}
  `,
};
