/* eslint-disable max-len */
import React, { useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import next from '../assets/arrow_next.svg';
import previous from '../assets/arrow_previous.svg';
import nextHover from '../assets/arrow_next_primary.svg';
import previousHover from '../assets/arrow_previous_primary.svg';
import mediaQueries from '../utils/mediaQueries';

const StyledContainerDesktop = styled.div`
  ${mediaQueries.mobile`
    display: none;
  `}
`;

const StyledImageScroller = styled(Slider)`
  height: 50vh !important;
  outline: none;

  & div.slick-slide {
    opacity: 0.5;
    padding: 0 5px;
    &.slick-active {
      opacity: 1;
    }
  }
`;

const StyledImageScrollerMobile = styled(Slider)`
  height: 30vh !important;
  outline: none;
  margin-left: calc(-50vw + 50%);
  width: 100vw;
  display: none;

  & div.slick-slide {
    opacity: 0.5;
    padding-right: 10px;
    &.slick-active {
      opacity: 1;
    }
  }

  ${mediaQueries.mobile`
    display: block;
  `}
`;

const StyledArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

const StyledImage = styled.img`
  height: 50vh;
  width: auto !important;
`;

const StyledImageMobile = styled.img`
  height: 30vh;
  width: auto !important;
`;

const StyledButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  margin-left: 10px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const StyledIconImg = styled.img`
  ${StyledButton}:hover & {
    display: none;
  }
  &:focus {
    outline: none;
  }
`;

const StyledIconImgHover = styled(StyledIconImg)`
  display: none;
  ${StyledButton}:hover & {
    display: flex;
  }
`;

export interface ImageGalleryProps {
  images: [string];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    variableWidth: true,
    adaptiveHeight: true,
  };
  const settingsMoblie = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    adaptiveHeight: true,
  };
  const slider = useRef(null);
  return (
    <>
      <StyledContainerDesktop>
        <StyledImageScroller {...settings} ref={slider}>
          {images?.map(image => (
            <StyledImage key={image['@id']} src={image['@link']} alt={image['@name']} />
          ))}
        </StyledImageScroller>
        <StyledArrow>
          <StyledButton onClick={() => slider.current.slickPrev()}>
            <StyledIconImg src={previous} alt="" />
            <StyledIconImgHover src={previousHover} alt="" />
          </StyledButton>
          <StyledButton onClick={() => slider.current.slickNext()}>
            <StyledIconImg src={next} alt="" />
            <StyledIconImgHover src={nextHover} alt="" />
          </StyledButton>
        </StyledArrow>
      </StyledContainerDesktop>
      <StyledImageScrollerMobile {...settingsMoblie}>
        {images?.map(image => (
          <StyledImageMobile key={image['@id']} src={image['@link']} alt={image['@name']} />
        ))}
      </StyledImageScrollerMobile>
    </>
  );
};

export default ImageGallery;
