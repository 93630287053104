import React from 'react';
import styled from 'styled-components';
import { Size, ThemeKeyOrFunc } from '../config/theme';
import mediaQueries from '../utils/mediaQueries';
import { selectSize } from '../utils/themeUtils';

const StyledDiv = styled.div<SpacerProps>`
  height: ${props => selectSize(props.size)}rem;
  ${props => (props.showOnlyOn && props.showOnlyOn === 'mobile' ? 'display: none;' : '')}
  ${mediaQueries.mobile`
    ${props => (props.showOnlyOn && props.showOnlyOn === 'desktop' ? 'display: none;' : '')}
    ${props => (props.showOnlyOn === 'mobile' ? 'display: block;' : '')}
  `}
`;

export interface SpacerProps {
  size?: ThemeKeyOrFunc<Size>;
  showOnlyOn?: 'mobile' | 'desktop';
}

const Spacer: React.FC<SpacerProps> = ({ size, showOnlyOn }) => (
  <StyledDiv size={size} showOnlyOn={showOnlyOn} />
);

export default Spacer;
