import { EditablePage, EditorContextHelper } from '@magnolia/react-editor';
import React from 'react';
import config from '../magnolia.config';
import { getAPIBase, getCurrentLanguage, removeCurrentLanguage } from './AppHelpers';
import loadingIcon from '../assets/loading-icon.svg';

interface PageLoaderState {
  content: unknown;
  init: unknown;
  templateDefinitions: unknown;
  pathname: string;
}

class PageLoader extends React.Component<unknown, Partial<PageLoaderState>> {
  state: Partial<PageLoaderState> = {};

  componentDidMount(): void {
    this.loadPage();
  }

  componentDidUpdate(): void {
    this.loadPage();
  }

  getPagePath = (): string => {
    const nodeName = process.env.REACT_APP_MGNL_APP_BASE;
    const currentLanguage = getCurrentLanguage();
    let path = (nodeName + window.location.pathname)
      .replace('.html', '')
      .replace(process.env.REACT_APP_MGNL_BASE_AUTHOR, '')
      .replace('//', '/');
    path = removeCurrentLanguage(path, currentLanguage);
    path += `?lang=${currentLanguage}`;

    return path;
  };

  loadPage = async (): Promise<void> => {
    // Bail out if already loaded content.
    if (this.state.pathname === window.location.pathname) return;

    const apiBase = getAPIBase();

    const pagePath = this.getPagePath();
    // console.log(`pagePath:${pagePath}`);
    const fullContentPath = apiBase + process.env.REACT_APP_MGNL_API_PAGES + pagePath;

    const pageResponse = await fetch(fullContentPath);
    const pageJson = await pageResponse.json();
    // console.log('page content: ', pageJson);

    const templateId = pageJson['mgnl:template'];
    // console.log('templateId:', templateId);

    let templateJson = null;
    if (EditorContextHelper.inEditor()) {
      const templateResponse = await fetch(
        `${apiBase + process.env.REACT_APP_MGNL_API_TEMPLATES}/${templateId}`
      );
      templateJson = await templateResponse.json();
      // console.log('definition:', templateJson);
    }

    this.setState({
      init: true,
      content: pageJson,
      templateDefinitions: templateJson,
      pathname: window.location.pathname,
    });
    /* Support for anchor links */
    if (window.location.hash) {
      setTimeout(() => {
        document.querySelector(window.location.hash)?.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    } else {
      window.scrollTo(0, 0);
    }
  };

  inEditorPreview(): boolean {
    const url = window.location.href;
    const inPreview = url.indexOf('mgnlPreview=true') > 0;
    // console.log(`inEditorPreview:${inPreview}`);
    return EditorContextHelper.inEditor() && inPreview;
  }

  render(): JSX.Element {
    const { content, init, templateDefinitions } = this.state;
    if (init) {
      // console.log('config:', config);
      // const isDevMode = process.env.NODE_ENV === 'development';
      // console.log("n:" + process.env.NODE_ENV)
      return (
        <EditablePage
          templateDefinitions={templateDefinitions || {}}
          content={content}
          config={config}
        />
      );
    }
    return (
      <img
        src={loadingIcon}
        style={{
          margin: '0',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }
}

export default PageLoader;
