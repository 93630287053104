import { EditableArea } from '@magnolia/react-editor';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import chevronDown from '../assets/chevron-down.svg';
import chevronUp from '../assets/chevron-up.svg';
import { selectColor, selectFont } from '../utils/themeUtils';
import mediaQueries from '../utils/mediaQueries';

const StyledExpander = styled.div`
  padding: 0;
  div[width*='85%'] {
    p {
      padding-left: 22%;
    }
  }
  div[width*='100%'] {
    p {
      padding-left: 18.5%;
    }
  }
`;

const StyledTitleContainer = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 2px solid ${selectColor('borderDark')};

  &.closed {
    color: ${selectColor('grey')};
  }

  &.open {
    color: ${selectColor('light')};
  }
`;

const StyledTitle = styled.h3`
  ${selectFont('titleSmall')};
  flex: 1;
  user-select: none;
`;

const StyledItems = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  > div {
    margin: 0.25em 0 1.5em;
  }
  margin-left: -50%;
  p > ul {
    margin-left: 25%;
  }
  ${mediaQueries.mobile`
   p > ul {
    margin-left: 5%;
  }
    margin-left: 0;
  `}
`;

const StyledImage = styled.img`
  margin-top: -4px;
`;

interface ExpanderProps {
  metadata: unknown;
  expanderItems: unknown;
  title: string;
}

const Expander: React.FC<ExpanderProps> = ({ metadata, expanderItems, title }) => {
  const ref = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  useEffect(() => {
    if (ref.current) {
      if (isCollapsed) {
        ref.current.style.maxHeight = null;
      } else {
        ref.current.style.maxHeight = ref.current.scrollHeight + 'px';
      }
    }
  }, [isCollapsed]);

  return (
    <StyledExpander>
      <StyledTitleContainer
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={isCollapsed ? 'closed' : 'open'}
      >
        <StyledTitle>{title}</StyledTitle>
        {isCollapsed ? (
          <StyledImage src={chevronDown} alt="" aria-hidden="true" />
        ) : (
          <StyledImage src={chevronUp} alt="" aria-hidden="true" />
        )}
      </StyledTitleContainer>

      <StyledItems ref={ref}>
        {expanderItems && (
          <EditableArea content={expanderItems} parentTemplateId={metadata['mgnl:template']} />
        )}
      </StyledItems>
    </StyledExpander>
  );
};

export default Expander;
