import React, { useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { getAPIBase } from '../helpers/AppHelpers';
import mediaQueries, { SIZES } from '../utils/mediaQueries';
import { selectColor, selectFont } from '../utils/themeUtils';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import arrowRightLong from '../assets/arrow_right_long.svg';
import { useSelector } from 'react-redux';
import { NotesState } from '../notesReducer';

const StyledImage = styled.img`
  cursor: pointer;
  width: 100%;
`;

styled(Link);
const StyledArrow = styled.img`
  display: none;
  float: right;
  padding-right: 50px;
  ${mediaQueries.mobile`
    width: 50px;
    left:30%;
    bottom: -10px;
    display:none;
  `}
`;

const StyledTitle = styled.span`
  ${selectFont('projectThumbnailTitle')}
  color: ${selectColor('grey')};
  margin-right: 1em;
  ${mediaQueries.mobile`
      ${selectFont('articleLinkInfo')}
  `}
`;

const StyledTitleHead = styled.span`
  ${selectFont('textLarge')}
  ${mediaQueries.mobile`
  ${selectFont('textMedium')}
   padding-left:0em;
  `}
  padding-left: 0.5em;
`;

const StyledLink = styled(Link)`
  ${mediaQueries.mobile`
    position: relative;
    display: block;
  `}
`;

const StyledMainLink = styled(Link)``;
const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  &:hover {
    background-color: ${selectColor('videoButtonBackground')};
  }
  &:hover ${StyledArrow} {
    display: inline;
  }
`;
const MainThumbnail = styled.div`
  padding-top: 1em;
  ${mediaQueries.mobile`
      padding-top:3em;;
  `}
`;
const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 5em;
  padding-left: 0.7em;
  height: 100%;
  ${mediaQueries.mobile`
      padding-top:unset;
      padding-left: 0em!important;
  `}
`;
const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0.5 1 0%;
  ${mediaQueries.mobile`
    display: block;
    flex:unset;
    flex-basis:unset;
  `}
`;
const SecondRowColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0.5 1 0%;
  ${mediaQueries.mobile`
      flex:unset;
      flex-basis:unset; 
  `}
`;
const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  border-top: 2px ${selectColor('videoButtonBackground')} solid;
  ${mediaQueries.mobile`
        border-top:unset;
  `}
`;

export interface HoverCircleProps {
  hoverCircleText: string;
}

export interface RedPageThumbnailBProps {
  selectedRedPage: string;
}

const RedPageThumbnailB: React.FC<RedPageThumbnailBProps> = ({ selectedRedPage }) => {
  const [project, setProject] = useState(null);
  const [category, setCategory] = useState(null);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const categoryList = useSelector<NotesState, NotesState['categoryList']>(
    state => state.categoryList
  );
  const redPageList = useSelector<NotesState, NotesState['redPageList']>(
    state => state.redPageList
  );
  const containerRef = useRef(null);

  React.useEffect(() => {
    async function fetchProject() {
      const response = await fetch(
        getAPIBase() + process.env.REACT_APP_MGNL_API_RED_PAGES + '?@jcr:uuid=' + selectedRedPage
      );
      const data = await response.json();
      setProject(data.results.pop());
    }

    fetchProject();
  }, [selectedRedPage]);
  React.useEffect(() => {
    async function fetchCategory(selectedCategory) {
      const response = await fetch(
        getAPIBase() + process.env.REACT_APP_MGNL_API_CATEGORIES + '?@jcr:uuid=' + selectedCategory
      );
      const data = await response.json();
      setCategory(data.results.pop());
    }

    if (project != null) {
      fetchCategory(project.page['category']);
    }
  }, [project]);

  React.useEffect(() => {
    if (
      (redPageList.length === 0 || redPageList.includes(selectedRedPage)) &&
      category &&
      (categoryList.length === 0 || categoryList.includes(category['@name'].toLowerCase()))
    ) {
      setShouldDisplay(true);
    } else if (
      !redPageList.includes(selectedRedPage) &&
      category &&
      categoryList.includes(category['@name'].toLowerCase())
    ) {
      setShouldDisplay(true);
    } else {
      setShouldDisplay(false);
    }
  }, [project, category, shouldDisplay, categoryList, redPageList]);

  return (
    <div>
      {project && category && shouldDisplay && (
        <MainThumbnail>
          <StyledMainLink key={project['@id']} to={project.page ? project.page['@path'] : ''}>
            <MainRow>
              <FirstColumn>
                <div>
                  <StyledLink key={project['@id']} to={project.page ? project.page['@path'] : ''}>
                    <StyledImage
                      ref={containerRef}
                      src={project.image['@link']}
                      alt={project.name}
                    />
                  </StyledLink>
                </div>
              </FirstColumn>
              <SecondColumn>
                <div>
                  <StyledTitleHead>{project.page['title']}</StyledTitleHead>
                  <SecondRow>
                    <SecondRowColumn>
                      <div>
                        <span>
                          <StyledTitle>{category['@name']}</StyledTitle>
                          <StyledTitle>
                            {' '}
                            <Moment format="ll">{project.page['date']}</Moment>
                          </StyledTitle>
                        </span>
                      </div>
                    </SecondRowColumn>
                    <SecondRowColumn>
                      <div>
                        <StyledArrow src={arrowRightLong} alt="arrow right long" />
                      </div>
                    </SecondRowColumn>
                  </SecondRow>
                </div>
              </SecondColumn>
            </MainRow>
          </StyledMainLink>
        </MainThumbnail>
      )}
    </div>
  );
};

export default RedPageThumbnailB;
