import React from 'react';
import styled from 'styled-components';
import mediaQueries from '../utils/mediaQueries';

const StyledImg = styled.img<{ desktopWidth: string }>`
  width: ${props => props.desktopWidth};
  margin: 1em 0;
  ${mediaQueries.mobile`
    width: 100%;
  `}
`;

export interface ImageProps {
  image: { '@link': string };
  title: string;
  desktopWidth: string;
}

const Image: React.FC<ImageProps> = ({ image, title, desktopWidth }) => (
  <StyledImg src={image['@link']} alt={title} desktopWidth={desktopWidth} />
);

export default Image;
