import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import linesVariant1 from '../assets/lines_variant1.svg';
import linesVariant2 from '../assets/lines_variant2.svg';
import linesVariant3 from '../assets/lines_variant3.svg';
import linesVariant3Mobile from '../assets/lines_variant3_mobile.svg';
import scroll from '../assets/scroll.svg';
import buildBig from '../assets/Build_Icon_big_WEBonly.svg';
import runBig from '../assets/Run_Icon_big_WEBonly.svg';
import designBig from '../assets/Design_Icon_big_WEBonly.svg';
import mediaQueries from '../utils/mediaQueries';
import { fluidProperty, relativeProperty, selectSize } from '../utils/themeUtils';

const ANIMATED_IMG_WIDTH_MOBILE = 60;
const ANIMATED_IMG_WIDTH_DESKTOP = 80;
const ICON_IMG_WIDTH_MIN = 150;
const ICON_IMG_WIDTH_MAX = 350;

const StyledContainer = styled.div<{ spacing: string; variant: string }>`
  position: relative;
  text-align: right;
  margin-left: 33.33%;
  margin-right: -1.5rem;
  ${props =>
    props.spacing === '50%'
      ? css`
          margin-bottom: -15rem;
        `
      : ''};
  ${props =>
    props.spacing === 'none'
      ? css`
          height: 0;
        `
      : ''};
  @media screen and (min-width: 2497px) {
    padding-top: 4em;
  }
  ${mediaQueries.desktop`
    margin-left: 33%;
  `}
  ${mediaQueries.mobile`
    width: calc(100% + (${selectSize('paddingLarge')}em * 2));
    margin-left: -${selectSize('paddingLarge')}em;
    margin-right: -${selectSize('paddingLarge')}em;
    ${props =>
      props.variant.indexOf('variant') === -1
        ? css`
            margin-top: 40vw;
          `
        : css`
            margin-top: 4em;
          `};
    ${props =>
      props.spacing === '50%'
        ? css`
            height: 20em;
          `
        : ''};
  `}
`;

const StyledImg = styled.img<{ variant: string }>`
  width: 100%;
  position: relative;
  z-index: -100;
  &.mobile {
    display: none;
  }
  ${mediaQueries.mobile`
    padding-top:55px;
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
    ${props =>
      props.variant === 'variant3'
        ? css`
            width: 200%;
          `
        : ''}
  `}
`;

function getImageVariant(variant: string) {
  switch (variant) {
    case 'variant1':
      return linesVariant1;
    case 'variant2':
    case 'variant3':
      return linesVariant2;
    case 'design':
    case 'build':
    case 'run':
      return linesVariant3;
    default:
      return linesVariant1;
  }
}

function getIconImg(variant: string) {
  switch (variant) {
    case 'design':
      return designBig;
    case 'build':
      return buildBig;
    case 'run':
      return runBig;
    default:
      return null;
  }
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const AnimatedImg = styled.img<{ variant: string }>`
  position: absolute;
  animation: ${rotate} 16s linear infinite;
  ${props =>
    props.variant === 'variant1'
      ? css`
          right: 7em;
          top: 10em;
          ${mediaQueries.desktop`
            top: 8em;
          `}
        `
      : css`
          ${fluidProperty('left', ANIMATED_IMG_WIDTH_MOBILE / -2, ANIMATED_IMG_WIDTH_DESKTOP / -2)}
          top: calc(50% - ${ANIMATED_IMG_WIDTH_DESKTOP / 2}px);
        `}
  ${fluidProperty('width', ANIMATED_IMG_WIDTH_MOBILE, ANIMATED_IMG_WIDTH_DESKTOP)}
  ${mediaQueries.mobile`
    left: 1.5rem;
    top: 30px;
  `}
`;

const IconImg = styled.img`
  position: absolute;
  ${fluidProperty('width', ICON_IMG_WIDTH_MIN, ICON_IMG_WIDTH_MAX)}
  ${fluidProperty('left', ICON_IMG_WIDTH_MIN / -2, ICON_IMG_WIDTH_MAX / -2)}
  top: calc(50% - ${relativeProperty(ICON_IMG_WIDTH_MAX / 2)});
  ${mediaQueries.mobile`
    width: 60vw;
    left: calc(50% - 30vw);
    top: -30vw;
  `}
`;

export interface GridBackgroundProps {
  z;
  variant: string;
  spacing: string;
}

const GridBackground: React.FC<GridBackgroundProps> = ({ variant, spacing }) => (
  <StyledContainer spacing={spacing} variant={variant}>
    {['design', 'build', 'run'].includes(variant) ? (
      <>
        <StyledImg
          src={getImageVariant(variant)}
          className="desktop"
          aria-hidden="true"
          alt="background image"
          variant={variant}
        />
        <StyledImg
          src={linesVariant3Mobile}
          className="mobile"
          aria-hidden="true"
          alt="background image"
          variant={variant}
        />
      </>
    ) : (
      <StyledImg
        variant={variant}
        src={getImageVariant(variant)}
        aria-hidden="true"
        alt="background image"
      />
    )}
    {variant === 'variant1' || variant === 'variant2' ? (
      <AnimatedImg
        src={scroll}
        variant={variant}
        aria-hidden="true"
        alt="animated background image"
      />
    ) : (
      ''
    )}
    {variant === 'design' || variant === 'build' || variant === 'run' ? (
      <IconImg src={getIconImg(variant)} aria-hidden="true" alt="icon background image" />
    ) : (
      ''
    )}
  </StyledContainer>
);
export default GridBackground;
