import styled from 'styled-components';
import mediaQueries from '../utils/mediaQueries';
import { selectColor, selectFont } from '../utils/themeUtils';

const StyledButton = styled.button`
  text-decoration: none;
  color: ${selectColor('light')};
  border: 2px solid ${selectColor('primary')};
  padding: 12px 60px;
  cursor: pointer;
  letter-spacing: 1px;
  display: block;
  text-align: center;

  ${selectFont('buttonText')}
  &:hover,
  &:focus {
    outline: ${selectColor('opaPrimary')} solid 4px;
  }

  ${mediaQueries.mobile`
    padding: 4px 40px;
  `}
`;

export default StyledButton;
