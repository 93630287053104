import React from 'react';
import styled from 'styled-components';
import facebook from '../assets/social/facebook-24px.svg';
import github from '../assets/social/github-logo.svg';
import instagram from '../assets/social/instagram.svg';
import linkedin from '../assets/social/linkedin.svg';
import meetup from '../assets/social/meetup.svg';
import share from '../assets/social/share.svg';
import twitter from '../assets/social/twitter.svg';
import youtube from '../assets/social/youtube.svg';
import { fluidProperty } from '../utils/themeUtils';

type SocialLogoType =
  | 'facebook'
  | 'github'
  | 'instagram'
  | 'linkedin'
  | 'meetup'
  | 'twitter'
  | 'youtube'
  | 'share';

const logoMap = {
  facebook: facebook,
  github: github,
  instagram: instagram,
  linkedin: linkedin,
  meetup: meetup,
  twitter: twitter,
  youtube: youtube,
  share: share,
};

const StyledImage = styled.img`
  ${fluidProperty('height', 35, 35)}
  ${fluidProperty('margin-right', 8, 8)}
  &:hover {
    filter: brightness(90%);
  }
`;

export interface SocialLinkProps {
  link: string;
  type: SocialLogoType;
}

const SocialLink: React.FC<SocialLinkProps> = ({ link, type }) => (
  <a href={link} target="_blank" rel="noreferrer">
    <StyledImage src={logoMap[type]} alt={type + ' link'} />
  </a>
);

export default SocialLink;
