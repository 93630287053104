import React, { useRef } from 'react';
import styled, { css, FlattenInterpolation, keyframes } from 'styled-components';
import { Color, ThemeKeyOrFunc } from '../config/theme';
import mediaQueries from '../utils/mediaQueries';
import { selectColor, selectFont } from '../utils/themeUtils';

const HeightContainer = styled.div`
  ${selectFont('titleLarge')}
  height: 1.3em;
`;

const AnimationContainer = styled.div`
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
`;

const Wrapper = styled.div`
  display: inline-block;
`;

const slideLeft = keyframes`
  from {
    margin-left: 0;
  }
  to {
    margin-left: -50%;
  }
`;

const slideRight = keyframes`
  from {
    margin-left: -50%;
  }
  to {
    margin-left: 0;
  }
`;

function invertSpeed(speed: number): number {
  return (speed - 11) * -1;
}

const LoopedText = styled.div<{ direction: 'ltr' | 'rtl'; speed: number }>`
  display: flex;
  white-space: nowrap;
  animation: ${props => (props.direction === 'ltr' ? slideRight : slideLeft)}
    ${props => invertSpeed(props.speed) * 12}s linear infinite;
  ${mediaQueries.desktop`
    animation-duration: ${props => invertSpeed(props.speed) * 8}s;
  `}
  ${mediaQueries.mobile`
    animation-duration: ${props => invertSpeed(props.speed) * 6}s;
  `}
`;

function getTextStyle(
  style: 'fill' | 'outline',
  color: ThemeKeyOrFunc<Color>
): FlattenInterpolation<never> {
  return style === 'outline'
    ? css`
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: ${selectColor(color)};
        ${mediaQueries.mobile`
          -webkit-text-stroke-width: 1px;
        `}
      `
    : css``;
}

const AnimatedText = styled.div<{ type: 'fill' | 'outline'; textColor: ThemeKeyOrFunc<Color> }>`
  color: ${props => selectColor(props.textColor)};
  ${props => getTextStyle(props.type, props.textColor)}
  ${selectFont('titleLarge')}
`;

export interface TextSliderProps {
  text?: string;
  direction?: 'ltr' | 'rtl';
  type: 'fill' | 'outline';
  textColor: ThemeKeyOrFunc<Color>;
  speed: number;
}

const TextSlider: React.FC<TextSliderProps> = ({ text, direction, type, textColor, speed }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);

  React.useEffect(() => {
    loopText(textRef.current, containerRef.current);
  }, []);

  function loopText(textElement: HTMLDivElement, containerElement: HTMLDivElement): void {
    const textHtml = textElement.innerHTML;
    for (
      let counter = 0;
      textElement.offsetWidth < containerElement.offsetWidth && counter < 100;
      counter++
    ) {
      textElement.innerHTML += textHtml;
    }
    const loopedTextElement = containerElement.querySelector('.looped-text');
    loopedTextElement.innerHTML = loopedTextElement.innerHTML + loopedTextElement.innerHTML;
  }

  return (
    <HeightContainer>
      <AnimationContainer ref={containerRef}>
        <Wrapper>
          <LoopedText direction={direction} speed={speed} className="looped-text">
            <AnimatedText type={type} textColor={textColor} ref={textRef}>
              {text} ·&nbsp;
            </AnimatedText>
          </LoopedText>
        </Wrapper>
      </AnimationContainer>
    </HeightContainer>
  );
};

export default TextSlider;
