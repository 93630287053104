export default {
  bigScreenWidth: 2496,
  desktopWidth: 1440,
  mobileWidth: 592,
  paddingTiny: 0.25,
  paddingSmall: 0.5,
  paddingMedium: 1,
  paddingLarge: 1.5,
  paddingHuge: 2.25,
  paddingExtraLarge: 4,
  MarginHuge: 2.5,
  spacerTiny: 0.25,
  spacerSmall: 0.5,
  spacerMedium: 1,
  spacerLarge: 3,
  spacerHuge: 6,
  spacerExtraHuge: 18,
  underlineWidth: '2px',
  columnPaddingRight: '6%',
};
