import { EditableArea } from '@magnolia/react-editor';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { screenReaderOnly } from '../utils/screenReaderOnly';

const ScreenReaderOnlyHeading1 = styled.h1`
  ${screenReaderOnly}
`;

interface BasicPageProps {
  main?: unknown;
  footer?: unknown;
  windowTitle: string;
  description?: string;
  keywords?: string;
  title?: string;
  twitterImage: { '@link': string };
  openGraphImage: { '@link': string };
  ogUrl?: string;
  twitterUrl?: string;
  ogType?: string;
  ogTitle?: string;
  ogDescription?: string;
  twitterCard?: string;
  twitterTitle?: string;
  twitterDescription?: string;
}

const Basic: React.FC<BasicPageProps> = ({
  main,
  footer,
  windowTitle,
  title,
  description,
  keywords,
  twitterImage,
  openGraphImage,
  ogUrl,
  twitterUrl,
  ogType,
  ogTitle,
  ogDescription,
  twitterTitle,
  twitterDescription,
  twitterCard,
}) => {
  let openGraphImgUrl, twitterImgUrl;

  if (openGraphImage != undefined || openGraphImage != null) {
    if (openGraphImage['@link'] != undefined) {
      openGraphImgUrl = 'https://www.panter.ch' + openGraphImage['@link'];
    }
  } else {
    openGraphImgUrl = '';
  }

  if (twitterImage != undefined || twitterImage != null) {
    if (twitterImage['@link'] != undefined) {
      twitterImgUrl = 'https://www.panter.ch' + twitterImage['@link'];
    }
  } else {
    twitterImgUrl = '';
  }
  return (
    <div className="Basic">
      <Helmet>
        <title>{windowTitle}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <ScreenReaderOnlyHeading1 role="status" aria-live="polite" aria-atomic="true">
        {title}
      </ScreenReaderOnlyHeading1>

      <main>{main && <EditableArea className="Area" content={main} />}</main>

      <footer className="Footer">
        {footer && <EditableArea className="Area" content={footer} />}
      </footer>
    </div>
  );
};

export default Basic;
