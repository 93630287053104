import Basic from './pages/Basic';
import Job from './pages/Job';
import Project from './pages/Project';
import Red from './pages/Red';
import Paragraph from './components/Paragraph';
import CallToActionButton from './components/CallToActionButton';
import ReadMoreLink from './components/ReadMoreLink';
import MultiColumnsLayout from './components/MultiColumnsLayout';
import Button from './components/Button';
import SectionTitle from './components/SectionTitle';
import ProjectThumbnail from './components/ProjectThumbnail';
import Spacer from './components/Spacer';
import TextSlider from './components/TextSlider';
import GridBackground from './components/GridBackground';
import JumpLinkList from './components/JumpLinkList';
import JumpLink from './components/JumpLink';
import GrayscaleImage from './components/GrayscaleImage';
import Footer from './components/Footer';
import FooterRichtext from './components/FooterRichtext';
import FooterList from './components/FooterList';
import FooterListItem from './components/FooterListItem';
import Newsletter from './components/Newsletter';
import Quote from './components/Quote';
import TeamList from './components/TeamList';
import LanguageSwitcher from './components/LanguageSwitcher';
import Video from './components/Video';
import ProjectLink from './components/ProjectLink';
import HeadlineAnimation from './components/HeadlineAnimation';
import ImageGallery from './components/ImageGallery';
import CategoryList from './components/CategoryList';
import CategoryItem from './components/CategoryItem';
import LinkBox from './components/LinkBox';
import LinkItem from './components/LinkItem';
import LightBackground from './components/LightBackground';
import SocialLinks from './components/SocialLinks';
import SocialLink from './components/SocialLink';
import Expander from './components/Expander';
import Accordion from './components/Accordion';
import Image from './components/Image';
import ArticleLink from './components/ArticleLink';
import WavingFlagAnimation from './components/WavingFlagAnimation';
import GreenCircleButton from './components/GreenCirleButton';
import CookieContainer from './components/CookieContainer';
import RedPageThumbnailList from './components/RedPageThumbnailList';
import RedPageThumbnailB from './components/RedPageThumbnailB';
import CategoryButtonItem from './components/CategoryButtonItem';
import RedPageThumbnailGallery from './components/RedPageThumbnailGallery';
import RedPageThumbnailA from './components/RedPageThumbnailA';
import CategoryStoriesTitle from './components/CategoryStoriesTitle';
import FooterLogo from './components/FooterLogo';

const config = {
  componentMappings: {
    'spa-lm:pages/basic': Basic,
    'spa-lm:pages/job': Job,
    'spa-lm:pages/project': Project,
    'spa-lm:pages/red': Red,

    'spa-lm:components/paragraph': Paragraph,
    'spa-lm:components/callToActionButton': CallToActionButton,
    'spa-lm:components/readMoreLink': ReadMoreLink,
    'spa-lm:components/multiColumnsLayout': MultiColumnsLayout,
    'spa-lm:components/button': Button,
    'spa-lm:components/sectionTitle': SectionTitle,
    'spa-lm:components/projectThumbnail': ProjectThumbnail,
    'spa-lm:components/spacer': Spacer,
    'spa-lm:components/textSlider': TextSlider,
    'spa-lm:components/gridBackground': GridBackground,
    'spa-lm:components/jumpLinkList': JumpLinkList,
    'spa-lm:components/jumpLink': JumpLink,
    'spa-lm:components/grayscaleImage': GrayscaleImage,
    'spa-lm:components/footer': Footer,
    'spa-lm:components/footerRichtext': FooterRichtext,
    'spa-lm:components/footerList': FooterList,
    'spa-lm:components/footerListItem': FooterListItem,
    'spa-lm:components/newsletter': Newsletter,
    'spa-lm:components/quote': Quote,
    'spa-lm:components/teams': TeamList,
    'spa-lm:components/languageSwitcher': LanguageSwitcher,
    'spa-lm:components/video': Video,
    'spa-lm:components/projectLink': ProjectLink,
    'spa-lm:components/headlineAnimation': HeadlineAnimation,
    'spa-lm:components/imageGallery': ImageGallery,
    'spa-lm:components/categoryList': CategoryList,
    'spa-lm:components/categoryItem': CategoryItem,
    'spa-lm:components/linkItem': LinkItem,
    'spa-lm:components/linkBox': LinkBox,
    'spa-lm:components/lightBackground': LightBackground,
    'spa-lm:components/socialLinks': SocialLinks,
    'spa-lm:components/socialLink': SocialLink,
    'spa-lm:components/expander': Expander,
    'spa-lm:components/accordion': Accordion,
    'spa-lm:components/image': Image,
    'spa-lm:components/articleLink': ArticleLink,
    'spa-lm:components/wavingFlagAnimation': WavingFlagAnimation,
    'spa-lm:components/greenCircleButton': GreenCircleButton,
    'spa-lm:components/cookieContainer': CookieContainer,
    'spa-lm:components/redPageThumbnailList': RedPageThumbnailList,
    'spa-lm:components/redPageThumbnailB': RedPageThumbnailB,
    'spa-lm:components/categoryButtonItem': CategoryButtonItem,
    'spa-lm:components/redPageThumbnailGallery': RedPageThumbnailGallery,
    'spa-lm:components/redPageThumbnailA': RedPageThumbnailA,
    'spa-lm:components/categoryStoriesTitle': CategoryStoriesTitle,
    'spa-lm:components/footerLogo': FooterLogo,
  },
};

export default config;
