import React from 'react';
import styled from 'styled-components';
import { selectColor, selectFont } from '../utils/themeUtils';

export interface FooterLinkProps {
  switchable: {
    field?: string;
    external?: string;
    internal?: string;
  };
}

const FooterLink: React.FC<FooterLinkProps> = ({ switchable, children }) => {
  const { field, external, internal } = switchable || {};

  if (field === 'external' && external) {
    return (
      <a href={external} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  } else if (field === 'internal' && internal) {
    return <a href={internal['@path']}>{children}</a>;
  }

  return <span>{children}</span>;
};

const Base = styled.li`
  ${selectFont('textSmall')};
  color: ${selectColor('grey')};
`;

export interface FooterListItemProps {
  switchable: {
    field?: string;
    external?: string;
    internal?: string;
  };
  text: string;
}

const FooterListItem: React.FC<FooterListItemProps> = ({ switchable, text }) => {
  return (
    <Base>
      {switchable?.field !== 'none' ? (
        <FooterLink switchable={switchable}>{text}</FooterLink>
      ) : (
        text
      )}
    </Base>
  );
};

export default FooterListItem;
