import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Container from './components/Container';
import GlobalStyles from './config/GlobalStyles';
import theme from './config/theme';
import './fonts.css';
import { getRouterBasename } from './helpers/AppHelpers';
import PageLoader from './helpers/PageLoader';
import Navigation from './components/Navigation';

const App = (): JSX.Element => (
  <BrowserRouter basename={getRouterBasename()}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Container>
        <header>
          <Navigation />
        </header>
        <Switch>
          <Route path="/" component={PageLoader} />
        </Switch>
      </Container>
    </ThemeProvider>
  </BrowserRouter>
);

export default App;
