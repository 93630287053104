import React from 'react';
import styled from 'styled-components';
import VideoPlayer from 'react-video-js-player';
import { selectColor, selectFont } from '../utils/themeUtils';
import mediaQueries from '../utils/mediaQueries';

const VideoContainer = styled.div`
  position: relative;
  margin: 1em 0;

  width: 85%;
  ${mediaQueries.mobile`
    width: 100%;
  `}

  div.vjs-poster {
    background-size: cover;
  }
`;

const StyledVideoPlayer = styled(VideoPlayer)`
  &.video-js .vjs-big-play-button {
    border: none;
    ${selectFont('textLarge')}
    background-color: ${selectColor('videoButtonBackground')};
    top: 0;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.vjs-has-started .vjs-big-play-button {
    display: none;
  }
  &.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: 'play';
    position: relative;
    font-family: inherit;
  }
  &.vjs-big-play-centered .vjs-big-play-button {
    margin-top: -1em;
    top: 0;
    left: 0;
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export interface VideoProps {
  video: { '@link': string };
  image: { '@link': string };
}

const Video: React.FC<VideoProps> = ({ video, image }) => {
  return (
    <VideoContainer>
      <StyledVideoPlayer
        controls={true}
        poster={image ? image['@link'] : ''}
        height="500"
        className="vjs-fluid"
        src={process.env.REACT_APP_MGNL_DAM_RAW + (video ? video['@link'] : '')}
      />
    </VideoContainer>
  );
};

export default Video;
