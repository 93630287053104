import React from 'react';
import styled from 'styled-components';
import { selectColor, selectFont, selectSize } from '../utils/themeUtils';
import mediaQueries from '../utils/mediaQueries';

const StyledParagraph = styled.div`
  ${selectFont('textSmall')};
  margin-bottom: ${selectSize('paddingTiny')}em;
  margin-top: 0.28em;
  ${mediaQueries.desktop`
      line-height: calc(24px + 7 * ((100vw - 592px) / 848))!important;
  `}
  ${mediaQueries.mobile`
    width: 50%;
    margin-bottom: 0;
    line-height: calc(24px + 7 * ((100vw - 592px) / 848))!important;

  `}

  a {
    color: ${selectColor('grey')};
    text-decoration: none;
    cursor: pointer;
  }

  u {
    display: block;
    text-decoration: none;
    margin-top: 0.5em;
  }
`;

export interface FooterRichtextProps {
  richText: string;
}

const FooterRichtext: React.FC<FooterRichtextProps> = ({ richText }) => (
  <>
    <StyledParagraph dangerouslySetInnerHTML={{ __html: richText }} />
  </>
);

export default FooterRichtext;
