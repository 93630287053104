import React from 'react';
import styled from 'styled-components';
import mediaQueries from '../utils/mediaQueries';
import sticker from '../assets/sticker.png';
import closeIcon from '../assets/close_icon.png';
import { useAcceptCookies } from '../hooks/useAcceptCookies';

const StickerGlobalDiv = styled.div`
  position: fixed;
  right: -25%;
  top: 20%;
  width: 24rem;
  height: 24rem;
  z-index: 2000;
  ${mediaQueries.mobile`
    display:none;
  `}
`;
const StickerGlobalAnchor = styled.a`
  height: 24rem;
  width: 24rem;
  display: flex;
  z-index: 200;
`;

const StickerGlobalInnerDiv = styled.div`
  position: fixed;
  right: 1rem;
  top: 4rem;
`;

const StickerCloseIcon = styled.button`
  display: block;
  z-index: 400;
  position: absolute;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  top: 70%;
  right: 50%;
`;
const StickerCloseIconImage = styled.img`
  height: auto;
  width: auto;
  transition: width height 0.3s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    opacity: 0.5;
  }
`;
const StickerImage = styled.img`
  height: auto;
  width: auto;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-duration: 0.5s;
  &:hover {
    transform: scale(1.1);
  }
`;

export interface GreenCircleButtonProps {
  link: {
    field?: string;
    external?: string;
    internal?: string;
  };
}

const GreenCircleButton: React.FC<GreenCircleButtonProps> = ({ link }) => {
  const { acceptedSticker, onAcceptSticker } = useAcceptCookies();

  return (
    <div>
      {!acceptedSticker && (
        <StickerGlobalDiv key="circleButton">
          <StickerGlobalAnchor
            href={link.field === 'external' ? link.external : link.internal['@path']}
            target="_blank"
          >
            <StickerGlobalInnerDiv>
              <StickerImage src={sticker} />
              <StickerCloseIcon onClick={onAcceptSticker}>
                <StickerCloseIconImage src={closeIcon} />
              </StickerCloseIcon>
            </StickerGlobalInnerDiv>
          </StickerGlobalAnchor>
        </StickerGlobalDiv>
      )}
    </div>
  );
};
export default GreenCircleButton;
