import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import { selectColor } from '../utils/themeUtils';
import styled from 'styled-components';
import Container from './Container';

const StyledLightBackground = styled.div`
  background-color: ${selectColor('lightBackground')};
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  }
`;

export interface LightBackgroundProps {
  content: unknown;
  metadata: unknown;
}

const LightBackground: React.FC<LightBackgroundProps> = ({ content, metadata }) => (
  <StyledLightBackground>
    <Container>
      {content && <EditableArea content={content} parentTemplateId={metadata['mgnl:template']} />}
    </Container>
  </StyledLightBackground>
);

export default LightBackground;
