import React from 'react';
import styled from 'styled-components';
import { selectColor, selectFont } from '../utils/themeUtils';
import { Link } from 'react-router-dom';

const StyledExternalLinkItem = styled.a`
  color: ${selectColor('grey')};
  ${selectFont('textTiny')}
  border-bottom: 2px solid transparent;
  vertical-align: middle;
  border-bottom-color: ${selectColor('primary')};

  &:hover {
    cursor: pointer;
    color: ${selectColor('light')};
  }
`;

const StyledinternalLinkItem = styled(Link)`
  color: ${selectColor('grey')};
  ${selectFont('textTiny')}
  border-bottom: 2px solid transparent;
  vertical-align: middle;
  border-bottom-color: ${selectColor('primary')};

  &:hover {
    cursor: pointer;
    color: ${selectColor('light')};
  }
`;

const StyledEmptyLinkItem = styled.span`
  color: ${selectColor('grey')};
  border-bottom: 2px solid transparent;
  ${selectFont('textTiny')}
  vertical-align: middle;
`;

export interface Link {
  field?: string;
  external?: string;
  internal?: string;
}

export interface LinkItemProps {
  text: string;
  link: Link;
  wrapped: boolean;
}

const LinkItem: React.FC<LinkItemProps> = ({ text, link, wrapped = true }) => {
  if (link.field === 'external') {
    return wrapped ? (
      <div>
        <StyledExternalLinkItem href={link.external} target="_blank" rel="noreferrer">
          {text}
        </StyledExternalLinkItem>
      </div>
    ) : (
      <StyledExternalLinkItem href={link.external} target="_blank" rel="noreferrer">
        {text}
      </StyledExternalLinkItem>
    );
  } else if (link.field === 'internal') {
    return wrapped ? (
      <div>
        <StyledinternalLinkItem to={link.internal['@path']}>{text}</StyledinternalLinkItem>
      </div>
    ) : (
      <StyledinternalLinkItem to={link.internal['@path']}>{text}</StyledinternalLinkItem>
    );
  } else {
    return wrapped ? (
      <div>
        <StyledEmptyLinkItem>{text}</StyledEmptyLinkItem>
      </div>
    ) : (
      <StyledEmptyLinkItem>{text}</StyledEmptyLinkItem>
    );
  }
};

export default LinkItem;
