import React, { useState } from 'react';
import styled from 'styled-components';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import mediaQueries from '../utils/mediaQueries';
import { selectColor, selectFont, selectSize } from '../utils/themeUtils';

const ICON_HEIGHT = '1.6em';
const ICON_RIGHT_PADDING = '0.3em';

const Base = styled.div`
  ${selectFont('textSmall')};
  margin-bottom: calc(${selectSize('spacerMedium')}em * 2);
  ${mediaQueries.mobile`
    width: 100%;
    margin-bottom: ${selectSize('spacerLarge')}em;;
  `}
`;

const Title = styled.h3`
  margin-bottom: ${selectSize('paddingTiny')}em;
`;

const NewsletterInputWrapper = styled.div`
  position: relative;
  width: 85%;
  height: calc(27px + 0.75em);
  padding-right: calc(${ICON_HEIGHT} + ${ICON_RIGHT_PADDING});
  border-bottom: ${selectSize('underlineWidth')} solid ${selectColor('primary')};

  ${mediaQueries.mobile`
    width: 100%;
  `}
`;

const NewsletterInput = styled.input`
  /*reset*/
  -webkit-appearance: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  /*styles*/
  position: absolute;
  bottom: 0;
  width: calc(100% - ${ICON_HEIGHT} - ${ICON_RIGHT_PADDING});
  padding: 0.375em 0;
  color: ${selectColor('grey')};
  font-size: inherit;
  font-family: inherit;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export interface NewsletterProps {
  title: string;
  label: string;
  code: unknown;
}

const Newsletter: React.FC<NewsletterProps> = ({ title, label }) => {
  const [email, setEmail] = useState('');
  const [notValidMail, setNotValidMail] = useState(true);

  const checkValidation = e => {
    if (email.length == 0 && !email.includes('@')) {
      setNotValidMail(true);
      setEmail(e.target.value);
    } else {
      setNotValidMail(false);
      setEmail(e.target.value);
    }
  };

  return (
    <Base>
      <Title>{title}</Title>
      <form
        action="https://static.mailerlite.com/webforms/submit/w4q3c5"
        data-code="w4q3c5"
        method="post"
        autoComplete="off"
      >
        <NewsletterInputWrapper>
          <NewsletterInput
            value={email}
            placeholder={label}
            onChange={checkValidation}
            aria-label="email"
            aria-required="true"
            type="email"
            data-inputmask=""
            aria-invalid="false"
            name="fields[email]"
            autoComplete="off"
          />
          <input type="hidden" name="ml-submit" value="1" />
          {notValidMail ? (
            <button
              type="submit"
              style={{ background: 'transparent', border: 'none' }}
              disabled={true}
            >
              <IconWrapper>
                <ArrowRightIcon style={{ width: '19px' }} />
              </IconWrapper>
            </button>
          ) : (
            <button type="submit" style={{ background: 'transparent', border: 'none' }}>
              <IconWrapper>
                <ArrowRightIcon style={{ width: '19px' }} />
              </IconWrapper>
            </button>
          )}
        </NewsletterInputWrapper>
      </form>
    </Base>
  );
};

export default Newsletter;
