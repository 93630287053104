import colors from './colors';
import fonts from './fonts';
import sizes from './sizes';
import zIndexes from './zIndexes';

/* eslint-disable no-unused-vars */
const theme = {
  sizes,
  colors,
  fonts,
  zIndexes,
};

export type Theme = typeof theme;

export type ThemeGroup = keyof Theme;
export type Color = keyof Theme['colors'];
export type Font = keyof Theme['fonts'];
export type Size = keyof Theme['sizes'];
export type ZIndex = keyof Theme['zIndexes'];
export interface WithThemeProps {
  theme: Theme;
}
export type ThemeKeySelector<T> = (props: unknown) => T;
export type ThemeKeyOrFunc<T> = T | ThemeKeySelector<T>;
export type TransformFunc = number | ((value: unknown) => string | number);

export default theme;
