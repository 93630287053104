export function followCursor(
  followingElement: HTMLElement,
  containerElement = document,
  delay = 0
): void {
  followingElement.style.pointerEvents = 'none';
  followingElement.style.display = 'none';
  followingElement.style.position = 'absolute';
  const onMouseMove = e => {
    setTimeout(() => {
      followingElement.style.left = e.pageX - followingElement.clientWidth / 2 + 'px';
      followingElement.style.top = e.pageY - followingElement.clientHeight / 2 + 'px';
    }, delay);
  };
  const onMouseEnter = () => {
    setTimeout(() => {
      followingElement.style.display = 'flex';
    });
  };
  const onMouseLeave = () => {
    followingElement.style.display = 'none';
  };
  const onMouseEnterDocument = e => {
    if (followingElement.contains(e.target)) {
      followingElement.style.display = 'flex';
    } else {
      followingElement.style.display = 'none';
    }
  };
  containerElement.addEventListener('mousemove', onMouseMove);
  containerElement.addEventListener('mouseenter', onMouseEnter);
  containerElement.addEventListener('mouseleave', onMouseLeave);
  if (containerElement !== document) {
    document.addEventListener('mouseenter', onMouseEnterDocument);
  }
}
