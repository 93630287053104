import React from 'react';
import { EditableArea } from '@magnolia/react-editor';

export const listItemsMobile = (eliminateFirstNode, Base, EditableArea, metadata) =>
  eliminateFirstNode.map((myData, index) => (
    <Base key={index}>
      {myData && <EditableArea content={myData} parentTemplateId={metadata['mgnl:template']} />}
    </Base>
  ));
export const listItemsDesktop = (arrayOfNodes, Base, EditableArea, metadata) =>
  arrayOfNodes.map((myData, index) => (
    <Base key={index}>
      {myData && <EditableArea content={myData} parentTemplateId={metadata['mgnl:template']} />}
    </Base>
  ));
export const firstItem = (firstNode, Base, EditableArea, metadata) =>
  firstNode.map((myData, index) => (
    <div key={index}>
      {myData && <EditableArea content={myData} parentTemplateId={metadata['mgnl:template']} />}
    </div>
  ));
