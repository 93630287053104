import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import mediaQueries from '../utils/mediaQueries';
import { selectColor, selectFont } from '../utils/themeUtils';

const AnimationContainer = styled.div`
  ${selectFont('headlineAnimation')}
  height: 4.55em;
  position: relative;
  overflow: hidden;
  margin-top: -0.9em;
  left: -0.4em;
  line-height: calc(156px + 123 * ((100vw - 592px) / 848)) !important;
  font-size: calc(124px + 76 * ((100vw - 592px) / 848)) !important;
  ${mediaQueries.mobile`
    height: 4.4em;
    top: 1.4em;
    margin-bottom: 2em;
    margin-top: 100px;
    left:0;
    line-height: calc(188px + 123 * ((100vw - 592px) / 848)) !important;
  `}
  @media screen and (max-width: 383px) {
    font-size: calc(114px + 76 * ((100vw - 592px) / 848)) !important;
  }
`;

const AnimatedText = styled.div<{ textNr: number }>`
  position: absolute;
  left: 0.29em;
  bottom: 0;

  ${mediaQueries.mobile`
    visibility: visible;
    left: 0.1em;
  `}
  ${props => getStyleForText(props.textNr)}
  ${props => getAnimationForText(props.textNr)}
`;

function getStyleForText(textNr: number) {
  if (textNr) {
    return css`
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: ${selectColor('primary')};
    `;
  }
}

function getAnimationForText(textNr: number) {
  return css`
    z-index: -${textNr};
    animation: ${moveUp(textNr)} 0.5s ease-in-out 1;
    transform: translateY(-${textNr * 65}%);
  `;
}

function moveUp(textNr: number) {
  return keyframes`
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    100% {
      opacity: 1;
      transform: translateY(-${textNr * 65}%);
    }
  `;
}

export interface HeadlineAnimationProps {
  text: string;
}

const HeadlineAnimation: React.FC<HeadlineAnimationProps> = ({ text }) => {
  return (
    <AnimationContainer>
      {[0, 1, 2, 3].map(textNr => (
        <AnimatedText key={'text-' + textNr} textNr={textNr} aria-hidden={textNr > 0}>
          {text}
        </AnimatedText>
      ))}
    </AnimationContainer>
  );
};

export default HeadlineAnimation;
