import React from 'react';
import styled from 'styled-components';
import mediaQueries from '../utils/mediaQueries';

const ImageContainer = styled.div`
  justify-content: center;
  align-items: center;
  height: 5rem;
  padding-top: 0.5em;
  ${mediaQueries.desktop`
    height: 3.5rem!important;
  `}
  ${mediaQueries.mobile`
    height: 6rem!important;
  `}
`;

const StyledImg = styled.img`
  max-width: 75%;
  filter: grayscale(100%);
}
`;

const StyledAnchor = styled.a`
   width: 85%;
display: flex;
-webkit-box-align: center;
align-items: center;

}
`;

export interface GrayscaleImageProps {
  image: { '@link': string };
  title: string;
  externalLink: string;
}

const GrayscaleImage: React.FC<GrayscaleImageProps> = ({ image, title, externalLink }) => (
  <ImageContainer>
    {externalLink ? (
      <StyledAnchor href={externalLink} target="_blank" rel="noreferrer">
        <StyledImg
          src={process.env.REACT_APP_MGNL_DAM_RAW + (image ? image['@link'] : '')}
          alt={title}
          title={title}
        />
      </StyledAnchor>
    ) : (
      <StyledImg
        src={process.env.REACT_APP_MGNL_DAM_RAW + (image ? image['@link'] : '')}
        alt={title}
        title={title}
      />
    )}
  </ImageContainer>
);

export default GrayscaleImage;
