import React, { useState } from 'react';
import styled from 'styled-components';
import { getAPIBase } from '../helpers/AppHelpers';
import { selectColor, selectFont, selectSize } from '../utils/themeUtils';
import Team from './Team';
import sizes from '../config/theme/sizes';
import mediaQueries from '../utils/mediaQueries';

const Base = styled.div`
  display: flex;
  ${mediaQueries.mobile`
    display: block;
  `}
`;

const TeamNameColumn = styled.div`
  width: 25%;
  padding-right: 2em;
  white-space: nowrap;
`;

const TeamTextColumn = styled.div`
  width: 50%;
  padding-right: 2em;
`;

const TeamMemberColumn = styled.div`
  width: 25%;
`;

const TeamName = styled.h3<{ isSelected: boolean }>`
  display: inline-block;
  font-size: 18px;
  line-height: 1.8em;
  border-bottom: ${selectSize('underlineWidth')} solid transparent;
  transition: all 0.25s linear;
  position: relative;
  cursor: pointer;
  margin-bottom: ${selectSize('spacerTiny')}em;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${selectColor('primary')};
    position: absolute;
    left: 0;
    bottom: 0;
    transform-origin: left;
    transform: scaleX(0);
    transition: 0.25s linear;
    ${props => (props.isSelected ? 'transform: scaleX(1)' : '')};
  }
  &:hover,
  &.active {
    color: ${selectColor('grey')};
  }

  &:hover:before,
  &.active:before {
    transform: scaleX(1);
  }
  ${mediaQueries.mobile`
    font-size: 14px;
    line-height: 29px;
    margin-bottom: 0em;
  `}
`;

const TeamText = styled.p`
  width: 100%;
  ${selectFont('teamText')};
  color: ${selectColor('grey')};
  ${mediaQueries.mobile`
    margin-bottom: ${selectSize('paddingMedium')}em;
  `}
`;

const TeamPlaceHolder = styled.div`
  min-height: calc(18px * 2.4);
`;

const fetchTeam = async teamId => {
  const response = await fetch(
    getAPIBase() + process.env.REACT_APP_MGNL_API_TEAMS + '?@jcr:uuid=' + teamId
  );
  const data = await response.json();
  return data.results[0];
};

export interface TeamListProps {
  selectedTeamIds: [string];
}

const TeamList: React.FC<TeamListProps> = ({ selectedTeamIds }) => {
  const [teamList, setTeamList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(null);
  const [isMobileLayout, setIsMobileLayout] = useState(false);

  React.useEffect(() => {
    teamList?.forEach((team, index) => {
      if (selectedTeam && selectedTeam['@id'] === team['@id']) {
        setSelectedTeamIndex(index);
      }
    });
  }, [selectedTeam]);

  React.useEffect(() => {
    Promise.all(selectedTeamIds.map(teamId => fetchTeam(teamId))).then(res => {
      setTeamList(res?.filter(team => team !== undefined));
    });
  }, [selectedTeamIds]);

  React.useEffect(() => {
    setSelectedTeam(teamList[0]);
  }, [teamList]);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > sizes.mobileWidth) {
        setIsMobileLayout(false);
      } else {
        setIsMobileLayout(true);
      }
    };
    window.addEventListener('resize', handleResize);

    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!teamList) {
    return null;
  }

  return (
    <Base>
      {isMobileLayout ? (
        teamList.map((team, teamIndex) => (
          <div key={team['@id']}>
            <TeamName
              onClick={() => setSelectedTeam(team)}
              isSelected={teamIndex === selectedTeamIndex}
            >
              {team.name}
            </TeamName>
            {teamIndex === selectedTeamIndex ? (
              <>
                <TeamText>{selectedTeam.text}</TeamText>
                <Team isMobileLayout={true} team={selectedTeam} />
              </>
            ) : null}
          </div>
        ))
      ) : (
        <>
          <TeamNameColumn>
            {teamList.map((team, teamIndex) => (
              <div key={team['@id']}>
                <TeamName
                  onClick={() => setSelectedTeam(team)}
                  isSelected={teamIndex === selectedTeamIndex}
                >
                  {team.name}
                </TeamName>
              </div>
            ))}
          </TeamNameColumn>
          {selectedTeam?.text ? (
            <TeamTextColumn>
              {teamList.map((team, teamIndex) =>
                selectedTeamIndex && teamIndex > selectedTeamIndex ? null : (
                  <TeamText key={team['@id']}>
                    {teamIndex === selectedTeamIndex ? team.text : ''}
                  </TeamText>
                )
              )}
            </TeamTextColumn>
          ) : null}

          <TeamMemberColumn>
            {teamList.map((team, teamIndex) =>
              selectedTeamIndex && teamIndex > selectedTeamIndex ? null : teamIndex ===
                selectedTeamIndex ? (
                <Team key={team['@id']} isMobileLayout={false} team={selectedTeam} />
              ) : (
                <TeamPlaceHolder key={team['@id']} />
              )
            )}
          </TeamMemberColumn>
        </>
      )}
    </Base>
  );
};

export default TeamList;
