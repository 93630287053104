import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import arrowRightLong from '../assets/arrow_right_long.svg';
import { getAPIBase } from '../helpers/AppHelpers';
import mediaQueries from '../utils/mediaQueries';
import { fluidProperty, selectColor, selectFont } from '../utils/themeUtils';

const StyledProjectLinkContainer = styled.div<{ styleSelect: string }>`
  position: relative;
  display: inline-block;
  ${mediaQueries.desktop`
   padding-top: 100px;
  margin-bottom: 0px;
  `}
  ${mediaQueries.bigScreen`
    padding-top: 100px;
  margin-bottom: 0px;
  `}
    ${mediaQueries.mobile`
       margin-top:4em;
        padding-top:0;
  `}
  &:hover {
    cursor: pointer;
    h1 {
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: ${selectColor('primary')};
      ${mediaQueries.mobile`
      -webkit-text-stroke-width: 1px;
    `}
    }
  }
  ${props =>
    props.styleSelect === 'withButton'
      ? css`
          padding-left: 8em;
        `
      : ''}

  ${props =>
    props.styleSelect === 'alignRight'
      ? css`
          margin-left: 25%;
        `
      : ''}
        ${props =>
    props.styleSelect === 'alignLift'
      ? css`
          margin-left: 7.5em;
        `
      : ''}

  ${mediaQueries.mobile`
    margin-top: 50px;
    padding-left:0px;
    ${props =>
      props.styleSelect === 'alignRight'
        ? css`
            margin-left: 10%;
          `
        : ''}
        ${props =>
          props.styleSelect === 'alignLift'
            ? css`
                margin-left: 0;
              `
            : ''}
  `}

  ${props =>
    props.styleSelect === 'withButton'
      ? css`
          display: flex;
          align-items: flex-end;
          ${mediaQueries.mobile`
            flex-direction: column;
            align-items: center;
          `}
          &:hover {
            img {
              animation: ${arrowAnimation} 0.5s;
            }
          }
        `
      : css`
          &:hover {
            & + img {
              display: block;
            }
          }
        `}
`;
const OuterLink = styled(Link)<{ styleSelect: string }>`
  cursor: pointer;
`;

const StyledProjectSpan = styled.span<{ styleSelect: string }>`
  ${props =>
    props.styleSelect === 'withButton'
      ? css`
          flex: 3;
          ${mediaQueries.mobile`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        `
      : ''}
`;

const StyledArrowSpan = styled.span<{ styleSelect: string }>`
  display: flex;
  flex-direction: column;
  ${props =>
    props.styleSelect === 'withButton'
      ? css`
          flex: 2;
        `
      : ''}
  ${mediaQueries.mobile`
      flex-direction: column-reverse;
      margin-top: 2rem;
    `}
`;

const StyledShortName = styled.h1<{ styleSelect: string }>`
  ${selectFont('projectLinkShortName')}
  display: inline;

  ${props =>
    props.styleSelect === 'withButton'
      ? css`
          ${mediaQueries.mobile`
            text-align: center;
          `}
        `
      : ''}
`;

const StyledImage = styled.img`
  position: fixed;
  height: 12rem;
  width: 19rem;
  top: -11rem;
  left: -20rem;
  transform: translate(0, 0);
  pointer-events: none;
  object-fit: cover;
  display: none;
  opacity: 80%;
`;

const StyledSubTitle = styled.h3`
  ${selectFont('styledSubTitle')}
  color: ${selectColor('grey')};
`;

const arrowAnimation = keyframes`
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`;

const StyledLongArrow = styled.img`
  ${fluidProperty('width', 170, 225)}
  ${fluidProperty('margin-top', 0, 10)}
  transition: transform 0.25s ease-out;
  transform-origin: left;
`;

const StyledButtonTitle = styled.div`
  ${selectFont('styledButtonTitle')}
  color: ${selectColor('grey')};

  ${mediaQueries.mobile`
      text-align: center;
            line-height:40px !important;
    `}
`;

export interface ProjectLinkProps {
  selectedProject: string;
  styleSelect: string;
}

const ProjectLink: React.FC<ProjectLinkProps> = ({ selectedProject, styleSelect }) => {
  const [project, setProject] = useState(null);
  const [mouseCoordinates, setMouseCoordinates] = useState({
    x: null,
    y: null,
  });

  const handleMouseMove = e => {
    setMouseCoordinates({ x: e.clientX, y: e.clientY });
  };

  React.useEffect(() => {
    async function fetchProject() {
      const response = await fetch(
        getAPIBase() + process.env.REACT_APP_MGNL_API_PROJECTS + '?@jcr:uuid=' + selectedProject
      );
      const data = await response.json();
      setProject(data.results.pop());
    }

    fetchProject();
  }, [selectedProject]);

  return (
    <div>
      <OuterLink styleSelect={styleSelect} to={project?.page ? project?.page['@path'] : ''}>
        <StyledProjectLinkContainer styleSelect={styleSelect}>
          {styleSelect === 'withButton' && (
            <StyledArrowSpan styleSelect={styleSelect}>
              <StyledLongArrow src={arrowRightLong} alt="arrow right long" />
              <StyledButtonTitle>Nächstes Kundenprojekt</StyledButtonTitle>
            </StyledArrowSpan>
          )}
          <StyledProjectSpan styleSelect={styleSelect}>
            <StyledShortName
              styleSelect={styleSelect}
              onMouseMove={e => {
                project?.image ? handleMouseMove(e) : null;
              }}
            >
              {project?.company}
            </StyledShortName>
            <StyledSubTitle>{project?.shortName}</StyledSubTitle>
          </StyledProjectSpan>
        </StyledProjectLinkContainer>
        {project?.image ? (
          <StyledImage
            src={project.image['@link']}
            alt={project.name}
            style={{
              transform: `translate(${mouseCoordinates.x || 0}px, ${mouseCoordinates.y || 0}px)`,
              // eslint-disable-next-line object-curly-newline
            }}
          />
        ) : null}
      </OuterLink>
    </div>
  );
};

export default ProjectLink;
