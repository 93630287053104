import React from 'react';
import styled from 'styled-components';
import mediaQueries from '../utils/mediaQueries';
import { useAcceptCookies } from '../hooks/useAcceptCookies';
import { selectColor } from '../utils/themeUtils';

const CookieGlobalDiv = styled.div`
  position: fixed;
  bottom: 0.5em;
  right: 0.5em;
  width: 435px;
  height: 221px;
  background-color: #1b1c1e;
  border: 1px solid dimgray;
  padding: 30px;
  cursor: normal;
  z-index: 2000;
  ${mediaQueries.mobile`
      bottom: 0;
      right: 0;
      width:100%;
      left:0;
      height:190px;
  `}
`;
const CookieGlobalParagraph = styled.p`
  font-size: 15.5px;
  line-height: 1.5em;
  color: white;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  > a {
    cursor: pointer;
  }
  > p {
    height: 99px;
    width: 375px;
  }
  ${mediaQueries.mobile`
     > p {
    height: auto;
    width: auto;
    word-break:break-all;
    font-size:14.5px;
    }
    

  `}
  @media screen and (max-width: 333px) {
    height: auto;
    width: auto;
    word-break: break-all;
    font-size: 13.5px;
  }
  cursor: normal;
`;

const StyledButton = styled.button`
  text-decoration: none;
  color: ${selectColor('light')};
  border: 2px solid ${selectColor('primary')};
  padding: 10px 110px;
  cursor: pointer;
  margin: auto;
  letter-spacing: 1px;
  display: block;
  text-align: center;
  background-color: #1b1c1e;
  margin-top: 15px;
  font-size: 15.5px;
  &:hover,
  &:focus {
    outline: ${selectColor('opaPrimary')} solid 4px;
  }

  ${mediaQueries.mobile`
    width: 100%;
    font-size:14.5px;
  `}
  @media screen and (max-width: 403px) {
    padding: 6px;
    margin: 0 auto;
    width: 100%;
    font-size: 14.5px;
  }
`;

export interface CookieContainerProps {
  richText: string;
}

const CookieContainer: React.FC<CookieContainerProps> = ({ richText }) => {
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies();

  return (
    <div>
      {!acceptedCookies && (
        <CookieGlobalDiv>
          <CookieGlobalParagraph
            dangerouslySetInnerHTML={{ __html: richText }}
          ></CookieGlobalParagraph>
          <StyledButton onClick={onAcceptCookies}>OK</StyledButton>
        </CookieGlobalDiv>
      )}
    </div>
  );
};
export default CookieContainer;
