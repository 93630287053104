import { EditableArea } from '@magnolia/react-editor';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Column from '../components/Column';
import Container from '../components/Container';
import { screenReaderOnly } from '../utils/screenReaderOnly';
import { fluidProperty, selectColor, selectFont, selectSize } from '../utils/themeUtils';
import Moment from 'react-moment';
import mediaQueries from '../utils/mediaQueries';
import share from '../assets/share.svg';
import { getAPIBase } from '../helpers/AppHelpers';

const ScreenReaderOnlyHeading1 = styled.h1`
  ${screenReaderOnly}
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${selectColor('grey')};
  ${fluidProperty('margin-top', 0, 220)}
  ${fluidProperty('margin-bottom', 60, 120)}
 
  ${mediaQueries.desktop`
  margin-top:100px;
  margin-bottom:100px;
  `}
   ${mediaQueries.bigScreen`
   margin-top:100px;
   margin-bottom:100px;
  `}
`;

const StyledRedTitle = styled.h3`
  ${selectFont('titleMedium')}
  text-align: center;
`;

const StyledLead = styled.p`
  ${selectFont('textLarge')}
  text-align: center;
  color: ${selectColor('light')};
  width: 100%;

  ${mediaQueries.mobile`
    width: 100%;
  `}
`;

const StyledTeamMember = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${fluidProperty('margin-top', 35, 130)}
`;

const StyledAvatar = styled.img`
  height: 60px;
  border-radius: 50%;
  vertical-align: middle;
`;

const StyledTeamMemberName = styled.h5`
  padding-top: 10px;
  ${selectFont('titleTiny')}
`;

const StyledInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 32px;
  ${fluidProperty('margin-top', 35, 40)}
  ${mediaQueries.mobile`
    flex-direction: column;
  `}
    ${mediaQueries.desktop`
    margin-top: 30px;
  `}
  ${mediaQueries.bigScreen`
   margin-top: 30px;
  `}
`;

const StyledColumn = styled.div`
  display: flex;
  margin-right: 0 !important;

  ${fluidProperty('margin-right', 0, 30)}

  &:last-of-type {
    margin-right: 0;
  }
  & div {
    margin-right: 0 !important;
    ${fluidProperty('margin-right', 15, 30)}

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledShareButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 20px;
  height: 40px;
  background-color: ${selectColor('shareButtonBG')};
  border: none;
  margin-left: 25px;

  &:focus {
    outline: none;
  }
  & img {
    display: none;
    ${mediaQueries.mobile`
      display: inline;
    `}
  }

  & span {
    color: ${selectColor('grey')};
    font-size: 15px;
    line-height: 32px;
    ${mediaQueries.mobile`
      display: none;
    `}
  }

  &:hover {
    & span {
      color: ${selectColor('light')};
    }
  }
`;
const StyledShareButtonWithoutEmail = styled(StyledShareButton)`
  ${mediaQueries.mobile`
       margin-left: 0px;
     
    `}
  & img {
    display: none;
    ${mediaQueries.mobile`
      display: inline;
    `}
  }
`;

const StyledEmail = styled.a`
  border: 2px solid ${selectColor('primary')};
  height: 40px;
  padding: 2px 12px;
  cursor: pointer;
  margin-left: 25px;

  &:hover {
    border-color: ${selectColor('opaPrimary')};
    color: ${selectColor('light')};
  }
`;
const StyledEmailWithoutShare = styled(StyledEmail)`
  ${mediaQueries.mobile`
      margin-left: 0px;
    `}
`;

const StyledImg = styled.img`
  width: 100vw;
  max-height: 800px;
  object-fit: cover;
`;

const StyledMain = styled.div`
  display: flex;
  width: 100%;
  ${mediaQueries.mobile`
    flex-direction: column;
  `}
`;

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
`;

const StyledEditableContentWrapper = styled.div`
  div[width*='85%'] {
    p {
      padding-left: 22%;
    }
  }
  div[width*='100%'] {
    p {
      padding-left: 18.5%;
    }
  }
  div[width*='85%'] {
    p > ul {
      margin: 0.5em 0;
      padding-left: 22%;
      > li {
        margin-left: 1.5em;
        list-style-type: disc;
      }
    }
    strong {
      font-weight: bold;
    }
  }
  ${mediaQueries.mobile`
    div[width*='85%'] {
    p > ul {
      margin: 0.5em 0;
      padding-left: 0;
      > li {
        margin-left: 1.5em;
        list-style-type: disc;
      }
    }
        strong { font-weight: bold; }

  }
`}
  ${mediaQueries.mobile`
      width: 100%;
      p{
      padding-left: 0 !important;
      }
      
      
  `}
`;

const StyledMainWithPadding = styled(StyledMain)`
  padding-bottom: ${selectSize('paddingHuge')}em;
`;

const StyledLightBackground = styled.div`
  background-color: ${selectColor('lightBackground')};
  width: 100vw;
  margin-bottom: 6em;

  color: ${selectColor('dark')};
  > div:first-child {
    margin: -97px auto -35px auto !important;
  }
  ${mediaQueries.mobile`
   > div:first-child{
    margin:-30px auto 0px auto!important;
   }
   
  `}
`;

const StyledModuleTitle = styled.h2`
  padding-top: ${selectSize('paddingSmall')}em;
  color: ${selectColor('grey')};
  ${selectFont('titleMedium')}
`;

const StyledWrapper = styled.div`
  width: 100%;
  ${mediaQueries.desktop`
      padding-top:2.5em !important;
      padding-bottom:2.5em !important;
  `}
  ${mediaQueries.bigScreen`
      padding-top:2.5em !important;
            padding-bottom:2.5em !important;

  `}
`;

export interface RedPageProps {
  main?: unknown;
  metadata?: unknown;
  windowTitle: string;
  title?: string;
  description?: string;
  keywords?: string;
  redTitle: string;
  lead: string;
  image: { '@link': string };
  twitterImage: { '@link': string };
  openGraphImage: { '@link': string };
  ogUrl?: string;
  twitterUrl?: string;
  ogType?: string;
  ogTitle?: string;
  ogDescription?: string;
  twitterCard?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  teamMember: string;
  category: { displayName: string };
  date: Date;
  showShareButton: string;
  shareButtonLabel: string;
  showEmailButton: string;
  emailButtonLabel: string;
  email: string;
  moduleTitle: string;
  module: 'none' | 'projects' | 'articles';
  projects?: unknown;
  article1: unknown;
  article2: unknown;
  footer?: unknown;
  multiColumnArea?: unknown;
}

const textToClipboard = text => {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

const Red: React.FC<RedPageProps> = ({
  main,
  windowTitle,
  title,
  description,
  keywords,
  redTitle,
  lead,
  image,
  twitterImage,
  openGraphImage,
  ogUrl,
  twitterUrl,
  ogType,
  ogTitle,
  ogDescription,
  twitterTitle,
  twitterDescription,
  twitterCard,
  teamMember,
  category,
  date,
  showShareButton,
  shareButtonLabel,
  showEmailButton,
  emailButtonLabel,
  email,
  moduleTitle,
  module,
  projects,
  article1,
  article2,
  footer,
  multiColumnArea,
}) => {
  const [member, setMember] = useState(null);
  const [dateFormat, setDateFormat] = useState(null);
  let openGraphImgUrl, twitterImgUrl;

  if (openGraphImage != undefined || openGraphImage != null) {
    if (openGraphImage['@link'] != undefined) {
      openGraphImgUrl = 'https://www.panter.ch' + openGraphImage['@link'];
    }
  } else {
    openGraphImgUrl = '';
  }

  if (twitterImage != undefined || twitterImage != null) {
    if (twitterImage['@link'] != undefined) {
      twitterImgUrl = 'https://www.panter.ch' + twitterImage['@link'];
    }
  } else {
    twitterImgUrl = '';
  }
  useEffect(() => {
    async function fetchTeamMember() {
      const response = await fetch(
        getAPIBase() + process.env.REACT_APP_MGNL_API_TEAM_MEMBERS + '?@jcr:uuid=' + teamMember
      );
      const data = await response.json();
      setMember(data.results[0]);
    }

    if (teamMember) {
      fetchTeamMember();
    }
  }, [teamMember]);

  useEffect(() => {
    setDateFormat(new Date(date) < new Date() ? 'll' : 'lll');
  }, [date]);
  return (
    <React.Fragment>
      <Helmet>
        <title>{windowTitle}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <StyledEditableContentWrapper>
        {multiColumnArea && <EditableArea content={multiColumnArea} />}
      </StyledEditableContentWrapper>
      <StyledMainContainer>
        <ScreenReaderOnlyHeading1 role="status" aria-live="polite" aria-atomic="true">
          {title}
        </ScreenReaderOnlyHeading1>
        <StyledContainer>
          <StyledRedTitle>{redTitle}</StyledRedTitle>
          <StyledLead dangerouslySetInnerHTML={{ __html: lead }} />
          {member && (
            <StyledTeamMember>
              {member.image ? <StyledAvatar src={member.image['@link']} alt="" /> : ''}
              <StyledTeamMemberName>{member.name}</StyledTeamMemberName>
            </StyledTeamMember>
          )}
          <StyledInfoContainer>
            <StyledColumn>
              {category && showEmailButton !== 'true' && showShareButton !== 'true' ? (
                <div>{category.displayName}</div>
              ) : (
                <div>{category.displayName}</div>
              )}
              {date && (
                <div>
                  <Moment format={dateFormat}>{date}</Moment>
                </div>
              )}
            </StyledColumn>
            <StyledColumn>
              {showShareButton === 'true' && showEmailButton === 'true' && (
                <StyledButtonContainer>
                  <StyledShareButton onClick={() => textToClipboard(window.location.href)}>
                    <img src={share} alt="" />
                    <span>{shareButtonLabel}</span>
                  </StyledShareButton>
                  <StyledEmail href={'mailto:' + email}>
                    {emailButtonLabel.toUpperCase()}
                  </StyledEmail>
                </StyledButtonContainer>
              )}
              {showEmailButton === 'true' && showShareButton !== 'true' && (
                <StyledButtonContainer>
                  <StyledEmailWithoutShare href={'mailto:' + email}>
                    {emailButtonLabel.toUpperCase()}
                  </StyledEmailWithoutShare>
                </StyledButtonContainer>
              )}
              {showEmailButton !== 'true' && showShareButton === 'true' && (
                <StyledButtonContainer>
                  <StyledShareButtonWithoutEmail
                    onClick={() => textToClipboard(window.location.href)}
                  >
                    <img src={share} alt="" />
                    <span>{shareButtonLabel}</span>
                  </StyledShareButtonWithoutEmail>
                </StyledButtonContainer>
              )}
            </StyledColumn>
          </StyledInfoContainer>
          {image && <StyledImg src={image['@link']} alt="" />}
        </StyledContainer>
        <StyledEditableContentWrapper>
          {main && <EditableArea content={main} />}
        </StyledEditableContentWrapper>

        {module !== 'none' && (
          <StyledLightBackground>
            <Container>
              <StyledMainWithPadding>
                <Column size={1}>
                  <StyledModuleTitle>{moduleTitle}</StyledModuleTitle>
                </Column>
                <Column size={2}>
                  {module === 'projects' && projects && (
                    <StyledWrapper>
                      <EditableArea content={projects} />
                    </StyledWrapper>
                  )}
                  {module === 'articles' && (
                    <StyledMain>
                      <Column size={1}>
                        <StyledWrapper>
                          <EditableArea content={article1} />
                        </StyledWrapper>
                      </Column>
                      <Column size={1}>
                        <StyledWrapper>
                          <EditableArea content={article2} />
                        </StyledWrapper>
                      </Column>
                    </StyledMain>
                  )}
                </Column>
              </StyledMainWithPadding>
            </Container>
          </StyledLightBackground>
        )}
      </StyledMainContainer>
      <footer className="Footer">
        {footer && <EditableArea className="Area" content={footer} />}
      </footer>
    </React.Fragment>
  );
};

export default Red;
