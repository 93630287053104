/* eslint-disable react/prop-types */
import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import styled, { css } from 'styled-components';
import { selectSize } from '../utils/themeUtils';
import Column from './Column';
import mediaQueries from '../utils/mediaQueries';

const StyledMultiColumnsLayout = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${mediaQueries.mobile`
    flex-direction: column;
  `}
`;

const StyledFirstColumn = styled(Column)<{ multiColumnsSelect: string }>`
  ${props =>
    props.multiColumnsSelect === '2'
      ? css`
          ${mediaQueries.mobile`
            flex-direction: column;
          `}
        `
      : ''};
`;

const StyledColumn = styled(Column)<{ multiColumnsSelect: string }>`
  flex: 2 1 0%;
  display: flex;

  ${mediaQueries.mobile`
    flex-direction: column;
  `}
`;

const StyledNestedColumn = styled.div<{ multiColumnsSelect: string }>`
  flex: 1;
  ${props =>
    props.multiColumnsSelect !== '1'
      ? css`
          padding-right: ${selectSize('columnPaddingRight')};
        `
      : ''}
  ${mediaQueries.mobile`
    ${props =>
      props.multiColumnsSelect !== '4'
        ? css`
            padding-right: 0;
          `
        : ''}
  `}
`;
const StyledTitleColumn = styled(Column)`
  flex: 1 1 0%;
  display: flex;
`;
const StyledButtonColumn = styled(Column)`
  flex: 1 1 0%;
  display: flex;
  ${mediaQueries.mobile`
  margin: 0 auto 50px auto;
}
`}
`;

export interface MultiColumnsProps {
  title: unknown;
  column1: unknown;
  column2: unknown;
  column3: unknown;
  column4: unknown;
  metadata: unknown;
  multiColumnsSelect: string;
}

const MultiColumnsLayout: React.FC<MultiColumnsProps> = ({
  title,
  column1,
  column2,
  column3,
  column4,
  metadata,
  multiColumnsSelect,
}) => {
  return (
    <StyledMultiColumnsLayout>
      {title[0] && title[0]['@nodes'].includes('link') ? (
        <StyledButtonColumn size={1}>
          {title && <EditableArea content={title} parentTemplateId={metadata['mgnl:template']} />}
        </StyledButtonColumn>
      ) : (
        <StyledTitleColumn size={1}>
          {title && <EditableArea content={title} parentTemplateId={metadata['mgnl:template']} />}
        </StyledTitleColumn>
      )}
      <StyledColumn size={2} multiColumnsSelect={multiColumnsSelect}>
        <StyledFirstColumn size={1} multiColumnsSelect={multiColumnsSelect}>
          <StyledNestedColumn multiColumnsSelect={multiColumnsSelect}>
            {column1 && (
              <EditableArea content={column1} parentTemplateId={metadata['mgnl:template']} />
            )}
          </StyledNestedColumn>
          {multiColumnsSelect === '1' ? (
            ''
          ) : (
            <StyledNestedColumn multiColumnsSelect={multiColumnsSelect}>
              {column2 && (
                <EditableArea content={column2} parentTemplateId={metadata['mgnl:template']} />
              )}
            </StyledNestedColumn>
          )}
        </StyledFirstColumn>
        {multiColumnsSelect === '4' ? (
          <Column size={1}>
            <StyledNestedColumn multiColumnsSelect={multiColumnsSelect}>
              {column3 && (
                <EditableArea content={column3} parentTemplateId={metadata['mgnl:template']} />
              )}
            </StyledNestedColumn>
            <StyledNestedColumn multiColumnsSelect={multiColumnsSelect}>
              {column4 && (
                <EditableArea content={column4} parentTemplateId={metadata['mgnl:template']} />
              )}
            </StyledNestedColumn>
          </Column>
        ) : (
          ''
        )}
      </StyledColumn>
    </StyledMultiColumnsLayout>
  );
};

export default MultiColumnsLayout;
