export type Action1 = { type: 'ADD_CATEGORY'; payload: string };
export type Action2 = { type: 'REMOVE_CATEGORY'; payload: string };
export type Action3 = { type: 'ADD_RED_PAGE'; payload: string };
export type Action4 = { type: 'REMOVE_RED_PAGE'; payload: boolean };

export const actionTypes = {
  ADD_CATEGORY: 'ADD_CATEGORY',
  REMOVE_CATEGORY: 'REMOVE_CATEGORY',
  ADD_RED_PAGE: 'ADD_RED_PAGE',
  REMOVE_RED_PAGE: 'REMOVE_RED_PAGE',
};

export const addCategoryList = (categoryList: string): Action1 => ({
  type: 'ADD_CATEGORY',
  payload: categoryList,
});
export const removeCategoryList = (categoryList: string): Action2 => ({
  type: 'REMOVE_CATEGORY',
  payload: categoryList,
});
export const addRedPage = (redPageList: string): Action3 => ({
  type: 'ADD_RED_PAGE',
  payload: redPageList,
});
export const removeRedPage = (redPageBoolean: boolean): Action4 => ({
  type: 'REMOVE_RED_PAGE',
  payload: redPageBoolean,
});
