import React from 'react';
import { EditableArea } from '@magnolia/react-editor';
import styled from 'styled-components';
import { selectColor } from '../utils/themeUtils';
import mediaQueries from '../utils/mediaQueries';

const StyledExpanderItem = styled.div`
  border-bottom: 2px solid ${selectColor('borderDark')};
  width: 80%;
  ${mediaQueries.mobile`
    width: 100%;
  `}
`;

interface AccordionProps {
  metadata: unknown;
  accordionItems: [string];
}

const Accordion: React.FC<AccordionProps> = ({ metadata, accordionItems }) => {
  return (
    <StyledExpanderItem>
      {accordionItems && (
        <EditableArea content={accordionItems} parentTemplateId={metadata['mgnl:template']} />
      )}
    </StyledExpanderItem>
  );
};

export default Accordion;
